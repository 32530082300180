import React from 'react';
import styled from 'styled-components';

import IconButton from '../../../../../../design_system/buttons/IconButton';
import Tooltip from '../../../../../../design_system/display/Tooltip/Tooltip';
import { fontMd1 } from '../../../../../../styled/TypeSystem';
import { sharedToolbarButtonStyles } from '../ToolbarButton';

// DS Override: Add shared styles for hover properties and increase size of button
const StyledIconButton = styled(IconButton)`
  ${sharedToolbarButtonStyles};

  ${fontMd1};
`;

const StyledTooltip = styled(Tooltip)`
  white-space: nowrap;
`;

type ToolTipProps =
  | { tooltipText: string; tooltipId: string }
  | { tooltipText?: never; tooltipId?: never };

export type Props = {
  ariaLabel: string;
  customIcon: React.ReactNode;
  onClick: () => void;
  id: string;
} & ToolTipProps;

const CustomToolBarButton = ({
  ariaLabel,
  customIcon,
  onClick,
  id,
  tooltipText,
  tooltipId,
}: Props) => {
  return (
    <>
      {tooltipText && <StyledTooltip id={tooltipId} text={tooltipText} />}
      <div data-for={tooltipId} data-tip='true'>
        <StyledIconButton ariaLabel={ariaLabel} customIcon={customIcon} id={id} onClick={onClick} />
      </div>
    </>
  );
};

export default CustomToolBarButton;
