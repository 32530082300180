import { toCamelCase } from '../lib/keyFormatConverter';
import { useGetCurrentAccountInfoQuery } from '../redux/services/resourceApis/accounts/accountsApi';
import { AccountState } from '../types/AccountState';

const REFETCH_ON_MOUNT_TIMEOUT = 5 * 60; // 5 Minutes

const useCurrentAccount = () => {
  // The LoadInitialState component will ensure that this query has completed successfully so there
  // is no need to test the query status for success.
  const { data: currentAccount, refetch } = useGetCurrentAccountInfoQuery(undefined, {
    refetchOnMountOrArgChange: REFETCH_ON_MOUNT_TIMEOUT,
  });

  if (!currentAccount) {
    throw new Error("Account can't be found");
  }

  return toCamelCase<AccountState & { refetch: () => void }>({ ...currentAccount, refetch });
};

export default useCurrentAccount;
