import { css } from 'styled-components';

import {
  fontLg1,
  fontLg2,
  fontMd1,
  fontMd2,
  fontMd3,
  fontMd4,
  fontMd5,
  fontSm4,
  fontSm5,
} from '../../../../styled/TypeSystem';

export type FontWeightType = 'bold' | 'semibold' | 'medium' | 'regular';
export type FontSize = 'sm4' | 'sm5' | 'md1' | 'md2' | 'md3' | 'md4' | 'md5' | 'lg1' | 'lg2';

export const FontSizeSelected = css<{ fontSize?: FontSize }>`
  ${({ fontSize }) => {
    switch (fontSize) {
      case 'sm4':
        return css`
          ${fontSm4};
        `;
      case 'md1':
        return css`
          ${fontMd1};
        `;
      case 'md2':
        return css`
          ${fontMd2};
        `;
      case 'md3':
        return css`
          ${fontMd3};
        `;
      case 'md4':
        return css`
          ${fontMd4};
        `;
      case 'md5':
        return css`
          ${fontMd5};
        `;
      case 'lg1':
        return css`
          ${fontLg1};
        `;
      case 'lg2':
        return css`
          ${fontLg2};
        `;
      case 'sm5':
      default:
        return css`
          ${fontSm5};
        `;
    }
  }}
`;

export const FontWeightSelected = css<{ fontWeight?: FontWeightType }>`
  ${({ fontWeight, theme: { constants } }) => {
    switch (fontWeight) {
      case 'bold':
        return css`
          font-weight: ${constants.fontBold};
        `;
      case 'semibold':
        return css`
          font-weight: ${constants.fontSemibold};
        `;
      case 'medium':
        return css`
          font-weight: ${constants.fontMedium};
        `;
      case 'regular':
        return css`
          font-weight: ${constants.fontRegular};
        `;
    }
  }}
`;
