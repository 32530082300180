import React, { useCallback } from 'react';
import styled from 'styled-components';

import { useEditorContext } from '../../../../../../../contexts/EditorContext';
import useCurrentAccount from '../../../../../../../hooks/useCurrentAccount';
import initTranslations from '../../../../../../../lib/initTranslations';
import { TruncatedText } from '../../../../../../design_system/Triage/TruncatedText';
import { fontMd1, fontSm4 } from '../../../../../../styled/TypeSystem';
import DropdownWithPoppableMenu from '../../../../../shared/DropdownWithPoppableMenu';
import { DropdownWrapper } from '../../../../../shared/DropdownWithPoppableMenu/styles';
import { selectedElement } from '../../../../lib/selectedElement';
import { FONT_FAMILIES } from '../../../../shared/constants/editor';

const DropdownText = styled.span`
  ${TruncatedText};
  ${fontSm4};
`;

const OptionWrapper = styled.div`
  min-width: 9rem;
  ${fontMd1};
`;

const t = initTranslations('editor');

const FontDropdownButton = () => {
  const {
    contentStyles: { p, h1, h2, h3, h4, a },
  } = useCurrentAccount();
  const { editor } = useEditorContext();

  const getFontFamily = () => {
    const selected = selectedElement(editor);
    let fontFamily;

    const textStyleFontFamily = editor.getAttributes('textStyle').fontFamily;
    if (textStyleFontFamily) {
      fontFamily = textStyleFontFamily.split(',')[0].replace(/['"]/g, '');
    } else {
      switch (selected?.tagName) {
        case 'H1':
          return h1?.fontFamily;
        case 'H2':
          return h2?.fontFamily;
        case 'H3':
          return h3?.fontFamily;
        case 'H4':
          return h4?.fontFamily;
        case 'a':
          return a?.fontFamily;
        default:
          return p?.fontFamily;
      }
    }

    if (!FONT_FAMILIES.includes(fontFamily)) {
      return p?.fontFamily;
    }

    return fontFamily;
  };

  const activeFontFamily = getFontFamily();
  const activeIndex = FONT_FAMILIES.findIndex((family) => family === activeFontFamily);
  const selectedOption = <DropdownText>{FONT_FAMILIES[activeIndex]}</DropdownText>;

  const setSelectedOption = useCallback(
    (index: number) => {
      const selectedFont = FONT_FAMILIES[index];
      editor.chain().focus().setFontFamily(selectedFont).run();
    },
    [editor]
  );

  return (
    <DropdownWrapper>
      <DropdownWithPoppableMenu
        activeOptionIndex={activeIndex}
        delayTooltipShow={250}
        id='font-dropdown'
        menuId='font-menu'
        options={FONT_FAMILIES.map((item, index) => (
          <OptionWrapper key={`option-${index}`} style={{ fontFamily: item }}>
            {item}
          </OptionWrapper>
        ))}
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
        style='toolbar'
        tooltipId='font-tooltip'
        tooltipPlace='bottom'
        tooltipText={t('font')}
      />
    </DropdownWrapper>
  );
};

export default FontDropdownButton;
