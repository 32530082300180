import React from 'react';

import { useEditorContext } from '../../../../../../../contexts/EditorContext';
import initTranslations from '../../../../../../../lib/initTranslations';
import ToolbarButton from '../ToolbarButton';

const t = initTranslations('editor');

const BoldToolbarButton = () => {
  const { editor } = useEditorContext();

  return (
    <ToolbarButton
      active={editor.isActive('bold')}
      ariaLabel={t('aria_label_bold_button')}
      className={editor.isActive('bold') ? 'is-active' : ''}
      iconName='bold'
      id='bold-button'
      onClick={() => editor.chain().focus().toggleBold().run()}
      tooltipId='bold-tooltip'
      tooltipText={t('bold')}
    />
  );
};

export default BoldToolbarButton;
