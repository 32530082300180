import { InsertRowRightOutlined } from '@ant-design/icons';
import React from 'react';

import { useEditorContext } from '../../../../../../../contexts/EditorContext';
import initTranslations from '../../../../../../../lib/initTranslations';
import CustomToolBarButton from '../CustomToolbarButton/CustomToolbarButton';

const t = initTranslations('editor');

const AddColumnAfterButton = () => {
  const { editor } = useEditorContext();

  return (
    <CustomToolBarButton
      ariaLabel={t('aria_label_column_after_button')}
      customIcon={<InsertRowRightOutlined rev={undefined} />}
      id='add-column-after-button'
      onClick={() => editor.chain().focus().addColumnAfter().run()}
      tooltipId='add-column-after-tooltip'
      tooltipText={t('add_column_after')}
    />
  );
};

export default AddColumnAfterButton;
