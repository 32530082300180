import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import { useEditorContext } from '../../../../../../../contexts/EditorContext';
import useCurrentAccount from '../../../../../../../hooks/useCurrentAccount';
import initTranslations from '../../../../../../../lib/initTranslations';
import { formatOptions } from '../../../../../../design_system/core/CoreSelectField/CoreSelectField';
import Tooltip from '../../../../../../design_system/display/Tooltip/Tooltip';
import { FontSizeDropdown } from '../../../../../shared/DropdownWithPoppableMenu/styles';
import { selectedElement } from '../../../../lib/selectedElement';
import { FONT_SIZES } from '../../../../shared/constants/editor';
import useSecondaryHighlight from '../../../../tiptap_extensions/paragraph/useSecondaryHighlight';

const DropdownWrapper = styled.div`
  min-width: 4rem;
`;

const fontSizeOptions = () => {
  return formatOptions(
    FONT_SIZES.map((fontSize) => ({
      label: fontSize,
      value: fontSize,
    }))
  );
};

const t = initTranslations('editor');

const FontSizeDropdownButton = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const {
    contentStyles: { p, h1, h2, h3, h4, a },
  } = useCurrentAccount();
  const { editor } = useEditorContext();
  const toggleHighlight = useSecondaryHighlight({ editor });
  const selected = selectedElement(editor);

  const getFontSize = () => {
    let fontSize;

    const textStyleFontSize = editor.getAttributes('textStyle').fontSize;
    if (textStyleFontSize) {
      fontSize = textStyleFontSize.replace('px', '');
    } else {
      switch (selected?.tagName) {
        case 'H1':
          return h1?.fontSize.replace('px', '');
        case 'H2':
          return h2?.fontSize.replace('px', '');
        case 'H3':
          return h3?.fontSize.replace('px', '');
        case 'H4':
          return h4?.fontSize.replace('px', '');
        case 'a':
          return a?.fontSize.replace('px', '');
        default:
          return p?.fontSize.replace('px', '');
      }
    }

    if (!FONT_SIZES.includes(fontSize)) {
      return p?.fontSize.replace('px', '');
    }

    return fontSize.replace('px', '');
  };

  const activeFontSize = getFontSize();

  const setSelectedOption = useCallback(
    (value: string) => {
      editor.chain().focus().setFontSize(value).run();
    },
    [editor]
  );

  const onMenuOpen = useCallback(() => {
    if (!!selected) {
      toggleHighlight(true);
    }

    setMenuOpen(true);
  }, [selected, toggleHighlight]);

  const onMenuClose = useCallback(() => {
    if (!!selected) {
      toggleHighlight(false);
      editor.commands.focus();
    }

    setMenuOpen(false);
  }, [editor.commands, selected, toggleHighlight]);

  return (
    <>
      {!menuOpen && (
        <Tooltip delayShow={250} id='font-size-tooltip' place='bottom' text={t('font_size')} />
      )}
      <DropdownWrapper data-for='font-size-tooltip' data-tip id='font-size-dropdown'>
        <FontSizeDropdown
          className='editor-font-size-dropdown'
          defaultValue={activeFontSize}
          expandMenuToFitOptions
          hideSelectedOptions={false}
          isMenuOpen={menuOpen}
          isSearchable
          onMenuClose={onMenuClose}
          onMenuOpen={onMenuOpen}
          onNonDefaultSelected={setSelectedOption}
          options={fontSizeOptions()}
          selectType='tertiary'
          size='sm'
          value={activeFontSize}
        />
      </DropdownWrapper>
    </>
  );
};

export default FontSizeDropdownButton;
