import React from 'react';

import { useEditorContext } from '../../../../../../../contexts/EditorContext';
import initTranslations from '../../../../../../../lib/initTranslations';
import ToolbarButton from '../ToolbarButton';

const t = initTranslations('editor');

const ItalicToolbarButton = () => {
  const { editor } = useEditorContext();

  return (
    <ToolbarButton
      active={editor.isActive('italic')}
      ariaLabel={t('aria_label_italic_button')}
      className={editor.isActive('italic') ? 'is-active' : ''}
      iconName='italic'
      id='italic-button'
      onClick={() => editor.chain().focus().toggleItalic().run()}
      tooltipId='italic-tooltip'
      tooltipText={t('italic')}
    />
  );
};

export default ItalicToolbarButton;
