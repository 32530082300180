export type FieldErrors = { [key: string]: string[] };
type FormError = { data: { baseErrors: FieldErrors[] } };

export const formErrorsFromError = (error: unknown): FieldErrors => {
  const castedError = error as FormError;
  if ('data' in castedError) {
    const { baseErrors } = castedError.data;
    return baseErrors[0];
  } else {
    throw new Error('Unhandled error type');
  }
};

type Error = { data: { baseErrors: string[] } } | { message: string };

export const messageFromError = (error: unknown | undefined): string[] | undefined => {
  if (!error) return undefined;

  let baseErrorsArray: string[] = [];
  const castedError = error as Error;

  if ('message' in castedError) {
    baseErrorsArray = [castedError.message];
  } else if ('data' in castedError) {
    const { baseErrors } = castedError.data;
    baseErrorsArray = baseErrors;
  } else {
    throw new Error('Unhandled error type');
  }

  return baseErrorsArray;
};
