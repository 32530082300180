import { IconName } from '@fortawesome/fontawesome-svg-core';
import React, { MouseEventHandler } from 'react';
import { ColorTokens } from 'saguaro';
import styled from 'styled-components';
import { Route } from 'type-route';

import { routes as routerRoutes } from '../../../application/publicApplication/applicationRouter';
import { fontSm4 } from '../../../styled/TypeSystem';
import Avatar from '../../display/avatar';
import Icon from '../../display/icons/Icon';
import { TruncatedText } from '../../Triage/TruncatedText';

const Badge = styled.a<{ readOnly: boolean }>`
  max-width: 100%;
  display: inline-flex;
  align-items: center;
  align-self: flex-start;
  flex-grow: 0;
  text-decoration: none;
  padding: ${({ theme: { constants } }) =>
    `${constants.spacerSm2} ${constants.spacerMd2} ${constants.spacerSm2} ${constants.spacerSm2}`};
  gap: ${({ theme: { constants } }) => constants.spacerSm2};
  font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
  color: ${({ theme: { vars } }) => vars.textDefault};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusEndcap};
  border: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface2}`};
  background: ${({ theme: { vars } }) => vars.foundationSurface1};

  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
    cursor: ${({ readOnly }) => (readOnly ? 'default' : 'pointer')};
  }

  ${fontSm4};
`;

const SourceIconCircle = styled.div<{ sourceName: Source }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;
  flex-shrink: 0;
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusCircle};
  background-color: ${({ sourceName, theme: { vars } }) => {
    const color = sourceMap[sourceName].color;
    if (!color) return 'transparent';
    return vars[color];
  }};
  color: ${({ sourceName, theme: { vars } }) => {
    const colorVar = sourceMap[sourceName].textColor;
    if (!colorVar) return vars.textDefault;
    return vars[colorVar];
  }};
  ${fontSm4};
`;

const SourceName = styled.div`
  max-width: 20rem;
  ${TruncatedText({})};
`;

const Subtext = styled.span`
  color: ${({ theme: { vars } }) => vars.textPlaceholder};
  padding: 0;
  margin: 0;
`;

const ClickDiv = styled.div`
  cursor: pointer;
`;

export const SourceArray = [
  'company',
  'group',
  'curriculum',
  'orgChart',
  'roleChart',
  'policy',
  'process',
  'users',
] as const;
export type Source = (typeof SourceArray)[number];

type SourceMapType = {
  [key in Source]:
    | {
        name: Source;
        icon: IconName;
        color: keyof ColorTokens;
        textColor?: keyof ColorTokens;
      }
    | {
        name: 'users';
        icon?: never;
        color?: never;
        textColor?: never;
      };
};

// TODO: Come back and change colors to DS colors when they have been ironed out
// https://trainual.slack.com/archives/C02FWGPQH4G/p1683911550633699?thread_ts=1683728352.006579&cid=C02FWGPQH4G
const sourceMap: SourceMapType = {
  company: { name: 'company', icon: 'file-alt', color: 'trainualBrandYellowSurface' },
  group: { name: 'group', icon: 'users', color: 'trainualBrandPurpleSurfaceLight' },
  curriculum: { name: 'curriculum', icon: 'file-alt', color: 'stateBackgroundSuccess' },
  orgChart: { name: 'orgChart', icon: 'screen-users', color: 'chartRed2' },
  policy: { name: 'policy', icon: 'file-alt', color: 'trainualBrandMagentaMedium' },
  process: {
    name: 'company',
    icon: 'file-alt',
    color: 'trainualBrandBlueMedium',
    textColor: 'textSurface',
  },
  roleChart: { name: 'roleChart', icon: 'user-group', color: 'trainualBrandYellowSurface' },
  users: { name: 'users' },
} as const;

export type OptionalSourceProps =
  | {
      sourceName: 'users';
      sourceImageUrl: string | undefined;
      userName?: string;
    }
  | {
      sourceName: Source;
      sourceImageUrl?: never;
      userName?: never;
    };

type NavigationProps =
  | {
      sourceRoute: Route<typeof routerRoutes>;
      sourceHref?: never;
    }
  | {
      sourceRoute?: never;
      sourceHref: string;
    }
  | {
      readOnly: true;
      sourceRoute?: Route<typeof routerRoutes>;
      sourceHref?: string;
    };

type RemovableProps =
  | {
      removable?: true;
      removeAction: MouseEventHandler<HTMLDivElement>;
    }
  | {
      removable?: false;
      removeAction?: never;
    };

export type SourceBadgeProps = {
  id?: string;
  readOnly?: boolean;
  sourceText: string;
  sourceSubtext?: string;
  tooltipId?: string;
} & NavigationProps &
  OptionalSourceProps &
  RemovableProps;

const SourceBadge = ({
  id,
  sourceHref,
  readOnly = false,
  removable = false,
  removeAction,
  sourceName,
  sourceText,
  sourceSubtext,
  sourceRoute,
  sourceImageUrl,
  tooltipId,
  userName,
}: SourceBadgeProps) => {
  const icon = sourceMap[sourceName].icon;
  const link = sourceRoute?.link || { href: sourceHref };

  return (
    <Badge
      id={id}
      {...(readOnly ? undefined : link)}
      readOnly={readOnly}
      {...(tooltipId && {
        'data-for': tooltipId,
        'data-tip': true,
      })}
    >
      <SourceIconCircle sourceName={sourceName}>
        {icon ? (
          <Icon name={icon} size='2xs' />
        ) : (
          <Avatar
            image={sourceImageUrl}
            name={userName ? userName : sourceText}
            shape='circle'
            size='xs'
          />
        )}
      </SourceIconCircle>
      <SourceName>{sourceText}</SourceName>
      {sourceSubtext && <Subtext>{sourceSubtext}</Subtext>}
      {!readOnly && !removable && <Icon name='chevron-right' />}
      {readOnly && removable && (
        <ClickDiv onClick={removeAction}>
          <Icon name='xmark' size='2xs' />
        </ClickDiv>
      )}
    </Badge>
  );
};

export default SourceBadge;
