import React from 'react';

import { useEditorContext } from '../../../../../../../contexts/EditorContext';
import initTranslations from '../../../../../../../lib/initTranslations';
import ToolbarButton from '../ToolbarButton';

const t = initTranslations('editor');

const OrderedListToolbarButton = () => {
  const { editor } = useEditorContext();

  return (
    <ToolbarButton
      active={editor.isActive('orderedList')}
      ariaLabel={t('aria_label_ordered_list_button')}
      className={editor.isActive('orderedList') ? 'is-active' : ''}
      iconName='list-ol'
      id='orderedList-button'
      onClick={() => editor.chain().focus().toggleOrderedList().run()}
      tooltipId='orderedList-tooltip'
      tooltipText={t('numbered_list')}
    />
  );
};

export default OrderedListToolbarButton;
