import { DeleteOutlined } from '@ant-design/icons';
import React from 'react';

import { useEditorContext } from '../../../../../../../contexts/EditorContext';
import initTranslations from '../../../../../../../lib/initTranslations';
import CustomToolBarButton from '../CustomToolbarButton/CustomToolbarButton';

const t = initTranslations('editor');

const DeleteColumnButton = () => {
  const { editor } = useEditorContext();

  return (
    <CustomToolBarButton
      ariaLabel={t('aria_label_delete_column_button')}
      customIcon={<DeleteOutlined rev={undefined} />}
      id='delete-column-button'
      onClick={() => editor.chain().focus().deleteColumn().run()}
      tooltipId='delete-column-tooltip'
      tooltipText={t('remove_column')}
    />
  );
};

export default DeleteColumnButton;
