import { Language, Tone } from '@tiptap-pro/extension-ai';
import { TextOptions } from '@tiptap-pro/extension-ai/dist/tiptap-pro/packages/extension-ai/src/types';
import { Editor } from '@tiptap/react';

import initTranslations from '../../../../../../../lib/initTranslations';
import { MultiSelectOptionProps, NestedOptionProps } from './MultiSelectDropdownOption';
import { DropdownOptionProps } from './SingleSelectDropdownOption';

const t = initTranslations('editor.ai');

export type CombinedOptionProps = DropdownOptionProps | MultiSelectOptionProps;

export const AIOptions = (editor: Editor, aiOptions: TextOptions): CombinedOptionProps[] => [
  {
    iconName: 'arrow-left-from-line',
    text: t('make_shorter'),
    onClick: () => editor.chain().focus().aiShorten(aiOptions).run(),
  },
  {
    iconName: 'arrow-right-from-line',
    text: t('make_longer'),
    onClick: () => editor.chain().focus().aiExtend(aiOptions).run(),
  },
  {
    iconName: 'microphone-stand',
    text: t('change_tone'),
    onClick: () => ({}),
    nestedOptions: changeToneOptionsArray(editor, aiOptions),
  },
  {
    iconName: 'sparkles',
    text: t('simplify'),
    onClick: () => editor.chain().focus().aiSimplify(aiOptions).run(),
  },
  {
    iconName: 'face-laugh',
    text: t('emojify'),
    onClick: () => {
      editor
        .chain()
        .focus()
        .aiEmojify({ ...aiOptions, stream: false })
        .run();
    },
  },
  {
    iconName: 'spell-check',
    text: t('fix_grammar_and_spelling'),
    onClick: () => editor.chain().focus().aiFixSpellingAndGrammar(aiOptions).run(),
  },
  {
    text: t('translate'),
    iconName: 'earth-america',
    onClick: () => ({}),
    nestedOptions: translateOptionsArray(editor, aiOptions),
  },
  {
    iconName: 'recycle',
    text: t('rephrase'),
    onClick: () => editor.chain().focus().aiRephrase(aiOptions).run(),
  },
  {
    iconName: 'pen-line',
    text: t('complete'),
    onClick: () =>
      editor
        .chain()
        .focus()
        .aiComplete({ ...aiOptions, append: true })
        .run(),
  },
  {
    iconName: 'arrow-down-short-wide',
    text: t('summarize'),
    onClick: () => editor.chain().focus().aiSummarize(aiOptions).run(),
  },
];

const tones: Tone[] = [
  'default',
  'academic',
  'business',
  'casual',
  'childfriendly',
  'confident',
  'conversational',
  'creative',
  'emotional',
  'excited',
  'formal',
  'friendly',
  'funny',
  'humorous',
  'informative',
  'inspirational',
  'memeify',
  'narrative',
  'objective',
  'persuasive',
  'poetic',
];

const changeToneOptionsArray = (editor: Editor, aiOptions: TextOptions): NestedOptionProps[] => {
  return tones.map((tone) => ({
    text: t(`tone.${tone}`),
    onClick: () => {
      editor
        .chain()
        .focus()
        .aiAdjustTone(<Tone>tone, aiOptions)
        .run();
    },
  }));
};

const languageNames: { [key in Language]?: string } = {
  ar: t('language.arabic'),
  bn: t('language.bengali'),
  nl: t('language.dutch'),
  en: t('language.english'),
  fr: t('language.french'),
  de: t('language.german'),
  hi: t('language.hindi'),
  id: t('language.indonesian'),
  it: t('language.italian'),
  ja: t('language.japanese'),
  ko: t('language.korean'),
  zh: t('language.mandarin_chinese'),
  mr: t('language.marathi'),
  fa: t('language.persian'),
  pl: t('language.polish'),
  pt: t('language.portuguese'),
  ru: t('language.russian'),
  es: t('language.spanish'),
  sw: t('language.swahili'),
  ta: t('language.tamil'),
  te: t('language.telugu'),
  tr: t('language.turkish'),
  uk: t('language.ukrainian'),
  ur: t('language.urdu'),
  vi: t('language.vietnamese'),
};

const translateOptionsArray = (editor: Editor, aiOptions: TextOptions): NestedOptionProps[] => {
  return Object.entries(languageNames).map(([code, name]) => ({
    text: name,
    onClick: () => {
      editor
        .chain()
        .focus()
        .aiTranslate(<Language>code, aiOptions)
        .run();
    },
  }));
};
