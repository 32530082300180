import React from 'react';
import styled from 'styled-components';

import initTranslations from '../../../../../../../lib/initTranslations';
import Icon from '../../../../../../design_system/display/icons/Icon';
import Loader from '../../../../../../design_system/Triage/Loader';
import { fontSm4 } from '../../../../../../styled/TypeSystem';

const SmartToolsWrapper = styled.div<{ isMenuOpen: boolean }>`
  display: flex;
  align-items: center;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
  padding: 0 ${({ theme: { constants } }) => constants.spacerSm3};
  color: ${({ isMenuOpen, theme: { vars } }) =>
    isMenuOpen ? vars.accentPrimaryDefault : vars.textDefault};
  transition: color 0.2s ease-in-out;
  ${fontSm4};
`;

type Props = {
  isMenuOpen: boolean;
  isAILoading?: boolean;
};

const t = initTranslations('editor.ai');

const SmartTools = ({ isMenuOpen, isAILoading }: Props) => {
  const buttonText = isAILoading ? t('loading') : t('smart_tools');

  return (
    <SmartToolsWrapper isMenuOpen={isMenuOpen}>
      {isAILoading ? <Loader /> : <Icon name='magic-wand-sparkles' />}
      <span>{buttonText}</span>
    </SmartToolsWrapper>
  );
};

export default SmartTools;
