import React from 'react';

import { useEditorContext } from '../../../../../../../contexts/EditorContext';
import initTranslations from '../../../../../../../lib/initTranslations';
import ToolbarButton from '../ToolbarButton';

const t = initTranslations('editor');

const SuperscriptToolbarButton = () => {
  const { editor } = useEditorContext();

  return (
    <ToolbarButton
      active={editor.isActive('superscript')}
      ariaLabel={t('aria_label_superscript_button')}
      iconName='superscript'
      id='superscript-button'
      onClick={() => editor.chain().focus().unsetSubscript().toggleSuperscript().run()}
      tooltipId='superscript-tooltip'
      tooltipText={t('superscript')}
      weight='regular'
    />
  );
};

export default SuperscriptToolbarButton;
