import React from 'react';

import { useEditorContext } from '../../../../../../../contexts/EditorContext';
import initTranslations from '../../../../../../../lib/initTranslations';
import ToolbarButton from '../ToolbarButton';

const t = initTranslations('editor');

const BlockQuoteToolbarButton = () => {
  const { editor } = useEditorContext();

  return (
    <ToolbarButton
      active={editor.isActive('blockquote')}
      ariaLabel={t('aria_label_block_quote')}
      iconName='block-quote'
      id='blockquote-button'
      onClick={() => editor.chain().focus().toggleBlockquote().run()}
      tooltipId='blockquote-tooltip'
      tooltipText={t('blockquote')}
    />
  );
};

export default BlockQuoteToolbarButton;
