import { IconName } from '@fortawesome/fontawesome-svg-core';
import React from 'react';
import styled from 'styled-components';

import Icon from '../../../../../../design_system/display/icons/Icon';
import { fontMd1 } from '../../../../../../styled/TypeSystem';

const OptionWrapper = styled.div<{ nestedOption: boolean }>`
  width: 100%;
  align-items: center;
  display: flex;
  gap: ${({ theme: { constants } }) => constants.spacerMd2};
  ${fontMd1};
`;

export type DropdownOptionProps = {
  iconName?: IconName;
  text: string;
  onClick: () => void;
  nestedOption?: boolean;
};

const SingleSelectDropdownOption = ({
  iconName,
  text,
  onClick,
  nestedOption = false,
}: DropdownOptionProps) => {
  return (
    <OptionWrapper nestedOption={nestedOption} onClick={onClick}>
      {iconName && <Icon name={iconName} />}
      <span>{text}</span>
    </OptionWrapper>
  );
};

export default SingleSelectDropdownOption;
