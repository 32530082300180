import React from 'react';

import initTranslations from '../../../../../../../../lib/initTranslations';
import ToolbarButton from '../../ToolbarButton';
import { useHandleIndentClick } from './handleIndentClick';

const t = initTranslations('editor');

const IndentToolbarButton = () => {
  const handleIndentClick = useHandleIndentClick();

  return (
    <ToolbarButton
      active={false}
      ariaLabel={t('aria_label_indent_left')}
      iconName='indent'
      id='indent-button'
      onClick={() => handleIndentClick('indent')}
      tooltipId='indent-tooltip'
      tooltipText={t('indent')}
      weight='regular'
    />
  );
};

export default IndentToolbarButton;
