import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';

import { useImageFlyoutContext } from '../../../../../contexts/ImageFlyoutContext';
import FlexContainer from '../../../../styled/FlexContainer';
import { FormProps } from '../../plugins/Embedly/EmbedlyInput';
import MediaFlyoutMenuItem from '../MediaFlyoutMenuItem/MediaFlyoutMenuItem';

const StyledFlexContainer = styled(FlexContainer)`
  margin: ${({ theme: { constants } }) => `${constants.spacerSm3} ${constants.spacerMd3} 0 0`};
`;

export type MenuItem = {
  SvgIcon: React.FC;
  title: string;
  Component: (formProps: FormProps) => JSX.Element;
  componentProps?: FormProps;
  id: string;
};

export type Props = {
  resetMedia: () => void;
  items: MenuItem[];
  selectedMenuItem: MenuItem;
  setSelectedMenuItem: Dispatch<SetStateAction<MenuItem>>;
  displayMenuItems?: boolean;
};

const MediaFlyoutMenu = ({
  resetMedia,
  items,
  selectedMenuItem,
  setSelectedMenuItem,
  displayMenuItems = true,
}: Props) => {
  const { imageError, setImageError } = useImageFlyoutContext();

  return (
    <FlexContainer>
      {displayMenuItems && (
        <StyledFlexContainer direction='column'>
          {items.map((item) => {
            return (
              <MediaFlyoutMenuItem
                isActive={selectedMenuItem.title === item.title}
                item={item}
                key={`${item.title}-index`}
                onClick={() => {
                  setSelectedMenuItem(item);
                  resetMedia();
                  imageError && setImageError(''); // Clear any errors when switching between menu options
                }}
              />
            );
          })}
        </StyledFlexContainer>
      )}
      <selectedMenuItem.Component {...selectedMenuItem.componentProps} />
    </FlexContainer>
  );
};

export default MediaFlyoutMenu;
