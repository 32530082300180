import React from 'react';
import styled from 'styled-components';

import { isColorWhite } from '../../../application/editor/shared/helpers';
import Icon from '../../display/icons/Icon';
import { isActiveColor } from '../../helpers';
import { SwatchProps } from './types';

const Swatch = styled.button<{ color: string }>`
  display: flex;
  width: 2.5rem;
  border: ${({ theme: { vars, constants }, color }) =>
    isColorWhite(color) ? `${constants.borderWidthLg} solid ${vars.borderDefault}` : 'none'};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusCircle};
  height: ${({ theme: { constants } }) => constants.heightLg};
  margin: ${({ theme: { constants } }) => `0 ${constants.spacerSm3} ${constants.spacerSm3} 0`};

  background-color: ${({ color }) => color};

  &:hover {
    cursor: pointer;
  }

  .fa-check {
    margin: auto;
    color: ${({ theme: { vars }, color }) =>
      isColorWhite(color) ? vars.textDefault : vars.foundationSurface1};
  }
`;

const FillSwatch = ({ color, onClick, activeColor }: SwatchProps) => {
  return (
    <Swatch color={color} id={`color-${color}-button`} onClick={() => onClick(color)}>
      {isActiveColor(color, activeColor) && <Icon name='check' weight='solid' />}
    </Swatch>
  );
};

export default FillSwatch;
