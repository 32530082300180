import { useContext } from 'react';

import { defaultContentStyles } from '../../../../.storybook/mocks/accounts/contentStyles';
import PublicContentStyles from '../components/PublicContentStyles';
import { ContentStyleDesignationContext } from '../contexts/ContentStyleDesignationContext';
import useCurrentAccount from './useCurrentAccount';

const useContentStyles = () => {
  const designation = useContext(ContentStyleDesignationContext);

  if (designation == 'trainual') {
    return PublicContentStyles;
  } else if (designation == 'mobile') {
    return defaultContentStyles;
  } else {
    // Violates law of hooks, but is okay since this value will never change within a single instance of React
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { contentStyles } = useCurrentAccount();
    return contentStyles;
  }
};

export default useContentStyles;
