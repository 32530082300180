import {
  fontLg4,
  fontMd2,
  fontMd4,
  fontSm2,
  fontSm3,
  fontSm4,
  fontSm5,
} from '../components/styled/TypeSystem';
import { Size } from '../types/design_system/Size';

export const getFontStyle = (size: Size) => {
  switch (size) {
    case '2xs':
      return fontSm2;
    case 'xs':
      return fontSm3;
    case 'sm':
      return fontSm4;
    case 'md':
      return fontSm5;
    case 'lg':
      return fontMd2;
    case 'xl':
      return fontMd4;
    case '2xl':
      return fontLg4;
  }
};
