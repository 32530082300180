import { Editor } from '@tiptap/core';
import { EditorState } from '@tiptap/pm/state';
import { EditorView } from '@tiptap/pm/view';

import { EditorTable } from '../tiptap_extensions/table/table';
import { isTableSelected } from '../tiptap_extensions/table/utils';

type TableAxis = 'column' | 'row';

type Props = {
  editor: Editor;
  view: EditorView;
  state: EditorState;
  from: number;
  tableHeading: TableAxis;
};

const isTableHeadingSelected = ({ editor, view, state, from, tableHeading }: Props) => {
  // We have to use a try-catch block here because the `domAtPos` method can throw an error in the console
  // At times docView is null and the primary cause is usage of the onCreate method in the useEditor hook
  // When collaboration is enabled, the onCreate method will go away and this try-catch block can be removed
  try {
    const domAtPos = view.domAtPos(from).node as HTMLElement;
    const nodeDOM = view.nodeDOM(from) as HTMLElement;
    const node = nodeDOM || domAtPos;
    const tableHeadingSelector = tableHeading === 'column' ? 'grip-column' : 'grip-row';

    if (!editor.isActive(EditorTable.name) || !node || isTableSelected(state.selection)) {
      return false;
    }

    let container: HTMLElement | null = node;

    while (container) {
      if (['TD', 'TH'].includes(container.tagName)) break;

      container = container.parentElement;
    }

    const gripRow =
      container &&
      container.querySelector &&
      container.querySelector(`a.${tableHeadingSelector}.selected`);

    return !!gripRow;
  } catch {
    return false;
  }
};

export default isTableHeadingSelected;
