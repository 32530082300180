import React from 'react';

import initTranslations from '../../../../../lib/initTranslations';
import EditorMenuOptions, { EditorMenuOptionProps } from '../../components/EditorMenuOptions';
import { useToggleList } from './useToggleList';

const t = initTranslations('editor.list');

export type Props = {
  setMenuOpen: (open: boolean) => void;
};

const ListMenu = ({ setMenuOpen }: Props) => {
  const { toggleList, isActiveList } = useToggleList();

  const listMenuOptions: EditorMenuOptionProps[] = [
    {
      active: isActiveList('bulletList'),
      iconName: 'list',
      iconWeight: 'regular',
      id: 'editor-bulleted-list',
      onClick: toggleList('bulletList', () => setMenuOpen(false)),
      title: t('bulleted_list'),
    },
    {
      active: isActiveList('orderedList'),
      iconName: 'list-ol',
      iconWeight: 'regular',
      id: 'editor-numbered-list',
      onClick: toggleList('orderedList', () => setMenuOpen(false)),
      title: t('numbered_list'),
    },
    {
      active: isActiveList('taskList'),
      iconName: 'list-check',
      iconWeight: 'regular',
      id: 'editor-task-list',
      onClick: toggleList('taskList', () => setMenuOpen(false)),
      title: t('check_list'),
    },
  ];

  return <EditorMenuOptions menuOptions={listMenuOptions} />;
};

export default ListMenu;
