import { ResolvedPos } from '@tiptap/pm/model';
import { useFormik } from 'formik';
import { useCallback, useEffect, useRef } from 'react';

import { useEditorContext } from '../../../../contexts/EditorContext';

export const useEditorColorHandler = (
  markTypeName: string,
  onSubmitColor: (color: string) => void
) => {
  const { editor } = useEditorContext();

  const formik = useFormik({
    initialValues: {
      color: '',
    },
    onSubmit: ({ color }) => {
      onSubmitColor(color);
    },
  });

  const currentColorRef = useRef(formik.values.color);

  const handleColorSet = useCallback(
    (color: string) => {
      onSubmitColor(color);
    },
    [onSubmitColor]
  );

  useEffect(() => {
    const { from } = editor.state.selection;
    const resolvedPos: ResolvedPos = editor.state.doc.resolve(from);

    if (resolvedPos.pos > 0) {
      const prevPos = resolvedPos.pos - 1;
      const nodeBeforeCursor = editor.state.doc.nodeAt(prevPos);

      if (nodeBeforeCursor) {
        const mark = nodeBeforeCursor.marks.find((mark) => mark.type.name === markTypeName);

        const newColor = mark ? mark.attrs.color : formik.initialValues.color;

        if (newColor !== currentColorRef.current) {
          currentColorRef.current = newColor;
          formik.setFieldValue('color', newColor);
        }
      }
    }
  }, [editor.state.doc, editor.state.selection, formik, handleColorSet, markTypeName]);

  return { formik, handleColorSet };
};
