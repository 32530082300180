import React from 'react';
import styled, { css } from 'styled-components';

import { isColorWhite } from '../../../application/editor/shared/helpers';
import Icon, { WeightProps } from '../../display/icons/Icon/Icon';
import { isActiveColor } from '../../helpers';
import { TextSwatchContainer } from './styles';
import { SwatchProps, TextSwatchVariations } from './types';

const StyledIcon = styled(Icon)<{ color: string }>`
  margin: auto;
  ${({ color }) =>
    isColorWhite(color) &&
    css`
      color: ${({ theme: { vars } }) => vars.foundationSurface1};
      stroke: ${({ theme: { vars } }) => vars.textDefault};
      stroke-width: 25;
    `}
`;

type Props = {
  variant?: TextSwatchVariations;
} & SwatchProps;

const TextSwatch = ({ color, onClick, activeColor, variant = 'text-color' }: Props) => {
  const iconWeightProps: WeightProps =
    variant == 'text-color' ? { weight: 'duotone', secondaryColor: color } : { weight: 'solid' };

  return (
    <TextSwatchContainer
      color={color}
      id={`color-${color}-button`}
      isActiveColor={isActiveColor(color, activeColor)}
      onClick={() => onClick(color)}
      variant={variant}
    >
      <StyledIcon color={color} name='a' {...iconWeightProps} />
    </TextSwatchContainer>
  );
};

export default TextSwatch;
