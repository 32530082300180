import React, { useState } from 'react';

import initTranslations from '../../../../../../../lib/initTranslations';
import Poppable from '../../../../../../Poppable';
import OutsideClickHandler from '../../../../../shared/OutsideClickHandler';
import ListMenu from '../../../../plugins/ListMenu/ListMenu';
import ToolbarButton from '../ToolbarButton';

const t = initTranslations('editor_toolbar');

const ListToolbarButton = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <OutsideClickHandler onOutsideClick={() => setMenuOpen(false)}>
      <Poppable
        isOpen={menuOpen}
        item={<ListMenu setMenuOpen={setMenuOpen} />}
        onClick={() => setMenuOpen(!menuOpen)}
        placement='bottom-start'
        trigger={
          <ToolbarButton
            active={menuOpen}
            ariaLabel={t('insert_options.aria_for_list')}
            iconName='list'
            id='list-button'
            tooltipId='list-tooltip'
            tooltipText={t('insert_options.list')}
          />
        }
      />
    </OutsideClickHandler>
  );
};

export default ListToolbarButton;
