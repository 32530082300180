import { IconName } from '@fortawesome/fontawesome-svg-core';
import React from 'react';
import styled, { css } from 'styled-components';

import IconButton from '../../../../../design_system/buttons/IconButton';
import { ButtonSize } from '../../../../../design_system/buttons/IconButton/iconButtonTypes';
import { IconWeight } from '../../../../../design_system/display/icons/Icon/IconTypes';
import Tooltip from '../../../../../design_system/display/Tooltip/Tooltip';
import { ConditionalTooltipProps } from '../../../../../design_system/display/Tooltip/types';

export const sharedToolbarButtonStyles = css<{ active?: boolean }>`
  color: ${({ active, theme: { vars } }) =>
    active ? vars.accentPrimaryDefault : vars.textDefault};
  background-color: ${({ active, theme: { vars } }) =>
    active ? vars.accentSubdued1 : vars.foundationSurface1};

  :hover {
    color: ${({ active, theme: { vars } }) =>
      active ? vars.accentPrimaryDefault : vars.textDefault};
  }

  :focus {
    color: ${({ active, theme: { vars } }) =>
      active ? vars.accentPrimaryDefault : vars.textDefault};
  }

  :hover,
  :focus {
    background-color: ${({ active, theme: { vars } }) => !active && vars.foundationHover};
  }
`;

// DS Override: Color and background color with active state to match toolbar designs
const StyledIconButton = styled(IconButton)<{ active?: boolean }>`
  min-width: 2rem;
  justify-content: center;
  // DS Override - No pointer events so event passes to the wrapper with className 'flyout-trigger' for Flyout handling
  svg {
    pointer-events: none;
  }
  ${sharedToolbarButtonStyles};
`;

export type Props = {
  active: boolean;
  ariaLabel: string;
  className?: string;
  dataTestId?: string;
  iconSize?: ButtonSize;
  id: string;
  iconName: IconName;
  onClick?: () => void;
  weight?: IconWeight;
} & ConditionalTooltipProps;

const ToolBarButton = ({
  active,
  ariaLabel,
  className = '',
  dataTestId,
  iconSize = 'sm',
  id,
  iconName,
  onClick,
  weight = 'solid',
  tooltipText,
  tooltipId,
}: Props) => {
  return (
    <>
      {tooltipText && tooltipId && (
        <Tooltip delayShow={250} id={tooltipId} place='bottom' text={tooltipText} />
      )}
      <StyledIconButton
        active={active}
        ariaLabel={ariaLabel}
        buttonSize={iconSize}
        className={className}
        dataTestId={dataTestId}
        dataTip
        id={id}
        name={iconName}
        onClick={onClick}
        tooltipId={tooltipId}
        weight={weight}
      />
    </>
  );
};

export default ToolBarButton;
