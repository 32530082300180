import isEmail from './isEmail';

export const userInitials = (name: string) => {
  if (!name) {
    return 'AA';
  }

  // Removes special symbols from a name
  const nameParts = name
    .replace(/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~`]/g, '')
    .trim()
    .split(/\s+/);
  const firstInitial = nameParts[0][0]?.toUpperCase();
  if (!firstInitial) return 'AA';
  let lastInitial = '';
  if (nameParts.length > 1) lastInitial = nameParts[nameParts.length - 1][0].toUpperCase();

  return `${firstInitial}${lastInitial}`;
};

export const userFirstName = (name: string) => {
  if (isEmail(name)) {
    return name;
  }

  return name.trim().split(' ')[0];
};
