import React from 'react';

import { useEditorContext } from '../../../../../../../contexts/EditorContext';
import initTranslations from '../../../../../../../lib/initTranslations';
import ToolbarButton from '../ToolbarButton';

const t = initTranslations('editor');

const OpenLinkToolbarButton = () => {
  const { editor } = useEditorContext();
  return (
    <ToolbarButton
      active={false}
      ariaLabel={t('aria_label_open_link_button')}
      iconName='external-link-alt'
      id='open-link-button'
      onClick={() => {
        window.open(editor.getAttributes('link').href, '_blank', 'noopener, noreferrer');
      }}
      weight='regular'
    />
  );
};

export default OpenLinkToolbarButton;
