import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import { useImageFlyoutContext } from '../../../../../contexts/ImageFlyoutContext';
import GiphyLogo from '../../../../../images/PoweredBy_200px-White_HorizText.png';
import { RegisteredMenuId } from '../../../../../lib/idRegistry';
import initTranslations from '../../../../../lib/initTranslations';
import { messageFromError } from '../../../../../redux/errors/helpers';
import Flyout from '../../../../design_system/overlays/flyout';
import { Placement } from '../../../../Poppable';
import { fontSm5 } from '../../../../styled/TypeSystem';
import useActiveMenuHandler from '../../../publicApplication/utils/useActiveMenuHandler';
import MediaFlyoutMenu, { MenuItem } from '../../components/MediaFlyoutMenu/MediaFlyoutMenu';
import { SharedFlyoutStyles } from '../../shared/styles';

const t = initTranslations('editor.image');

// DS Override: Expanding the flyout and changing the borders to match the designs in Figma
const StyledFlyout = styled(Flyout)`
  min-width: 37.5rem;
  ${SharedFlyoutStyles};

  .flyout-footer {
    padding-top: 0;
  }
`;

const PoweredByWrapper = styled.div`
  margin-top: ${({ theme: { constants } }) => constants.spacerMd3};
  position: absolute;
  left: 1rem;
`;

const PoweredByPixibay = styled.span`
  ${fontSm5};
  color: ${({ theme: { vars } }) => vars.textPlaceholder};
`;

const determineImageObject = (menuId: string): string => {
  switch (menuId) {
    case 'giphy':
      return t('gifs');
    case 'stock_images':
      return t('stock_images');
    default:
      return t('images');
  }
};

interface Props {
  placement?: Placement;
  trigger: ReactElement;
  menuId: RegisteredMenuId;
  menuItems: MenuItem[];
  uploadAfterClosed?: () => void;
}

const ImageFlyout = ({
  placement = 'top-start',
  trigger,
  menuId,
  menuItems,
  uploadAfterClosed,
}: Props) => {
  const { closeMenu } = useActiveMenuHandler({ menuId });

  const [selectedMenuItem, setSelectedMenuItem] = useState<MenuItem>(menuItems[0]);
  const {
    imageFile,
    selectedImageUrl,
    imageDataDispatch,
    submitting,
    setSubmitting,
    uploadImage,
    uploadResult,
    isDisabled,
    fetchUrl,
    isFetchingUrl,
    setImageError,
  } = useImageFlyoutContext();

  const { isSuccess, error, isError, reset, isLoading } = uploadResult;

  const onSubmit = useCallback(() => {
    if (imageFile) {
      const formData = new FormData();
      formData.append('file', imageFile);

      uploadImage(formData);
      reset();
    }
  }, [reset, imageFile, uploadImage]);

  const resetImageData = useCallback(() => {
    setImageError('');
    setSelectedMenuItem(menuItems[0]);
    !uploadAfterClosed && imageDataDispatch({ type: 'resetImageData' });
  }, [imageDataDispatch, menuItems, setImageError, uploadAfterClosed]);

  useEffect(() => {
    if (submitting) {
      setSubmitting(false);
      onSubmit();
    }

    if (isSuccess && uploadResult.data) {
      closeMenu();
      reset();
      resetImageData();
    }

    if (isError) {
      reset();
      imageDataDispatch({ type: 'resetImageData' });
      setImageError(messageFromError(error)?.join(', ') || t('error.something_went_wrong'));
    }
  }, [
    closeMenu,
    error,
    imageDataDispatch,
    isError,
    isSuccess,
    onSubmit,
    reset,
    resetImageData,
    setImageError,
    setSubmitting,
    submitting,
    uploadResult.data,
  ]);

  const resetMedia = useCallback(() => {
    imageDataDispatch({ type: 'resetImageData' });
  }, [imageDataDispatch]);

  const handlePrimaryButtonClick = useCallback(async () => {
    if (uploadAfterClosed) {
      uploadAfterClosed();
      resetImageData();
      closeMenu();
      return;
    }

    try {
      if (imageFile) {
        setSubmitting(true);
      } else if (selectedImageUrl) {
        await fetchUrl(selectedImageUrl);
        setSubmitting(true);
      }
    } catch {
      const object = determineImageObject(selectedMenuItem.id);

      setImageError(t('loading_error', { object }));
      setSubmitting(false);
    }
  }, [
    uploadAfterClosed,
    resetImageData,
    closeMenu,
    imageFile,
    selectedImageUrl,
    setSubmitting,
    fetchUrl,
    selectedMenuItem.id,
    setImageError,
  ]);

  const handleSecondaryButtonClick = useCallback(() => {
    resetImageData();
    closeMenu();
  }, [closeMenu, resetImageData]);

  const buttonTitle = selectedMenuItem.title == t('giphy') ? t('gif_button') : t('image_button');

  return (
    <StyledFlyout
      className={menuId}
      id={menuId}
      loading={isFetchingUrl || isLoading || submitting}
      onClose={resetImageData}
      placement={placement}
      primaryButtonDisabled={isDisabled}
      primaryButtonTask={handlePrimaryButtonClick}
      primaryButtonText={buttonTitle}
      secondaryButtonTask={handleSecondaryButtonClick}
      secondaryButtonText={t('cancel')}
      strategy='absolute'
      title={t('title')}
      triggerButton={trigger}
    >
      <MediaFlyoutMenu
        items={menuItems}
        resetMedia={resetMedia}
        selectedMenuItem={selectedMenuItem}
        setSelectedMenuItem={setSelectedMenuItem}
      />
      {selectedMenuItem.id !== 'upload' && (
        <PoweredByWrapper>
          {selectedMenuItem.id === 'giphy' ? (
            <img alt={t('gif_logo_alt')} src={GiphyLogo} />
          ) : (
            <PoweredByPixibay>{t('powered_by_pixibay')}</PoweredByPixibay>
          )}
        </PoweredByWrapper>
      )}
    </StyledFlyout>
  );
};

export default ImageFlyout;
