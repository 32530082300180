import React from 'react';

import { MenuOptionProps } from '../../../../design_system/core/MenuOptions';
import { StyledMenuOption, StyledMenuOptions } from './styles';

export type EditorMenuOptionProps = {
  active: boolean;
} & MenuOptionProps;

const EditorMenuOptions = ({ menuOptions }: { menuOptions: EditorMenuOptionProps[] }) => {
  return (
    <StyledMenuOptions>
      {menuOptions.map((item) => {
        return (
          <StyledMenuOption
            active={item.active}
            iconName={item.iconName}
            iconWeight={item.iconWeight}
            id={item.id}
            key={item.id}
            onClick={item.onClick}
            title={item.title}
          />
        );
      })}
    </StyledMenuOptions>
  );
};

export default EditorMenuOptions;
