import { Editor } from '@tiptap/core';
import React, { ReactElement, ReactNode, useContext, useState } from 'react';

import { EmbedlyMenuItemSource } from '../components/application/editor/plugins/Embedly/EmbedlyMenu/EmbedlyMenu';
import { Button, ButtonGroup } from '../components/application/editor/toolbar';
import ToolbarButtons from '../components/application/editor/toolbar/buttons';
import { useEditorContext } from './EditorContext';

export type ToolbarContext = 'docked' | 'bubble';

type EditorToolbarContext = {
  activeButtons: ButtonGroup[];
  buttons: ButtonGroup[];
  context: ToolbarContext;
  setSelectedEmbedMenuItem: React.Dispatch<React.SetStateAction<EmbedlyMenuItemSource | undefined>>;
  selectedEmbedMenuItem: EmbedlyMenuItemSource | undefined;
};

type EditorToolbarProps = {
  children: ReactNode;
  buttons: ButtonGroup[];
  context: ToolbarContext;
};

const EditorToolbarContext = React.createContext({} as EditorToolbarContext);
export const useEditorToolbarContext = () => useContext(EditorToolbarContext);

export const activeButtonsFilter = (
  editor: Editor,
  context: ToolbarContext,
  buttons: ButtonGroup[]
) => {
  return buttons
    .map((buttonGroup) => {
      return {
        name: buttonGroup.name,
        buttons: buttonGroup.buttons.filter((button: Button) => {
          const buttonConfig = ToolbarButtons[button];
          return !buttonConfig.shouldHide || !buttonConfig.shouldHide({ editor, context });
        }),
      };
    })
    .filter((buttonGroup) => buttonGroup.buttons.length > 0);
};

const EditorToolbarProvider = ({
  children,
  buttons,
  context,
}: EditorToolbarProps): ReactElement => {
  const [selectedEmbedMenuItem, setSelectedEmbedMenuItem] = useState<
    EmbedlyMenuItemSource | undefined
  >(undefined);
  const { editor } = useEditorContext();

  const activeButtons = activeButtonsFilter(editor, context, buttons);

  return (
    <EditorToolbarContext.Provider
      value={{
        selectedEmbedMenuItem,
        setSelectedEmbedMenuItem,
        buttons,
        activeButtons,
        context,
      }}
    >
      {children}
    </EditorToolbarContext.Provider>
  );
};

export { EditorToolbarProvider };
