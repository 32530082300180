import React from 'react';

import ColorPickerIcon from './ColorPickerIcon';
import { ColorPickerIconButton, ColorPickerTextIcon } from './styles';
import { ColorPickerTriggerProps } from './types';

const ColorPickerTrigger = ({ ariaLabel, color, type, buttonId }: ColorPickerTriggerProps) => {
  const commonProps = { 'aria-label': ariaLabel, id: buttonId };

  const IconWrapper = type === 'background-color' ? ColorPickerIconButton : ColorPickerTextIcon;

  return (
    <IconWrapper data-testid='color-picker-trigger' {...commonProps}>
      <ColorPickerIcon color={color} type={type} />
    </IconWrapper>
  );
};

export default ColorPickerTrigger;
