import React from 'react';

import { useEditorContext } from '../../../../../../../contexts/EditorContext';
import initTranslations from '../../../../../../../lib/initTranslations';
import ToolbarButton from '../ToolbarButton';

const t = initTranslations('editor');

const SubscriptToolbarButton = () => {
  const { editor } = useEditorContext();

  return (
    <ToolbarButton
      active={editor.isActive('subscript')}
      ariaLabel={t('aria_label_subscript_button')}
      iconName='subscript'
      id='subscript-button'
      onClick={() => editor.chain().focus().unsetSuperscript().toggleSubscript().run()}
      tooltipId='subscript-tooltip'
      tooltipText={t('subscript')}
      weight='regular'
    />
  );
};

export default SubscriptToolbarButton;
