import React, { useCallback } from 'react';

import { useEditorContext } from '../../../../../../../contexts/EditorContext';
import initTranslations from '../../../../../../../lib/initTranslations';
import Icon from '../../../../../../design_system/display/icons/Icon';
import DropdownWithPoppableMenu from '../../../../../shared/DropdownWithPoppableMenu';
import { TEXT_ALIGN } from '../../../../shared/constants/editor';

const t = initTranslations('editor');

const TextAlignDropdownButton = () => {
  const { editor } = useEditorContext();

  const activeIndex = TEXT_ALIGN.findIndex(({ align }) => editor.isActive({ textAlign: align }));
  const selectedIndex = Math.max(activeIndex, 0); // default to 0 when no active found
  const selectedOption = <Icon name={TEXT_ALIGN[selectedIndex].icon} />;

  const setSelectedOption = useCallback(
    (index: number) => {
      const selectedTextAlign = TEXT_ALIGN[index].align;
      editor.chain().focus().setTextAlign(selectedTextAlign).run();
    },
    [editor]
  );

  return (
    <DropdownWithPoppableMenu
      activeOptionIndex={activeIndex}
      delayTooltipShow={250}
      id='text-align-dropdown'
      menuId='text-align-menu'
      menuItemDirection='row'
      menuItemType='icon'
      options={TEXT_ALIGN.map((item, index) => (
        <div className={`align-${item.align}-menu-item`} key={`option-${index}`}>
          <Icon name={item.icon} />
        </div>
      ))}
      selectedOption={selectedOption}
      setSelectedOption={setSelectedOption}
      style='toolbar'
      tooltipId='text-align-tooltip'
      tooltipPlace='bottom'
      tooltipText={t('align')}
    />
  );
};

export default TextAlignDropdownButton;
