import React from 'react';

import { useEditorContext } from '../../../../../../../contexts/EditorContext';
import initTranslations from '../../../../../../../lib/initTranslations';
import ToolbarButton from '../ToolbarButton';

const t = initTranslations('editor');

const StrikeToolbarButton = () => {
  const { editor } = useEditorContext();

  return (
    <ToolbarButton
      active={editor.isActive('strike')}
      ariaLabel={t('aria_label_strike_through_button')}
      iconName='strikethrough'
      id='strike-button'
      onClick={() => editor.chain().focus().toggleStrike().run()}
      tooltipId='strike-tooltip'
      tooltipText={t('strikethrough')}
    />
  );
};

export default StrikeToolbarButton;
