import { IconName } from '@fortawesome/fontawesome-svg-core';
import React from 'react';
import styled from 'styled-components';

import { useEditorFlyoutContext } from '../../../../../../contexts/EditorFlyoutContext';
import { ButtonSize } from '../../../../../design_system/buttons/DefaultButton/ButtonTypes';
import Icon from '../../../../../design_system/display/icons/Icon';
import { IconWeight } from '../../../../../design_system/display/icons/Icon/IconTypes';
import { fontSm4 } from '../../../../../styled/TypeSystem';
import { sharedToolbarButtonStyles } from './ToolbarButton';

const StyledButton = styled.button<{
  active?: boolean;
  disabled: boolean;
  isBtnRef: boolean;
}>`
  display: ${({ isBtnRef }) => (isBtnRef ? 'none' : 'flex')};
  align-items: center;
  flex-direction: column;
  gap: ${({ theme: { constants } }) => constants.spacerSm2};
  min-width: 3.5rem;
  padding: ${({ theme: { constants } }) => constants.spacerSm3};
  border: none;
  outline: none;
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  ${sharedToolbarButtonStyles};
`;

const TextWrapper = styled.span<{ active?: boolean }>`
  font-weight: ${({ active, theme: { constants } }) =>
    active ? constants.fontMedium : constants.fontRegular};
  pointer-events: none;
  ${fontSm4}
`;

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  pointer-events: none;
`;

const SvgWrapper = styled.div<{ active: boolean }>`
  /* Copied similar styles to fontawesome icon */
  width: 21px;
  height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;

  svg {
    width: 26px;
    height: 26px;
    overflow: visible;

    path {
      fill: ${({ active, theme: { vars } }) =>
        active ? vars.accentPrimaryDefault : vars.textDefault};
    }
  }
`;

type CommonProps = {
  disabled?: boolean;
  dataTestId?: string;
  active: boolean;
  ariaLabel: string;
  className?: string;
  id: string;
  onClick?: () => void;
  label: string;
  btnRef?: React.RefObject<HTMLButtonElement>;
};

type IconProps = CommonProps & {
  iconType: 'name';
  iconName: IconName;
  iconSize?: ButtonSize;
  weight?: IconWeight;
};

type IconSVGProps = CommonProps & {
  iconType: 'svg';
  Svg: React.FC;
};

export type LabelToolBarButtonProps = IconProps | IconSVGProps;

const LabelToolbarButton = ({
  active,
  ariaLabel,
  className,
  dataTestId,
  disabled = false,
  id,
  label,
  onClick,
  ...props
}: LabelToolBarButtonProps) => {
  const { triggerBtnRef } = useEditorFlyoutContext();
  const btnRef = triggerBtnRef ? triggerBtnRef : undefined;

  return (
    <StyledButton
      active={active}
      aria-label={ariaLabel}
      className={className ?? ''}
      data-active={active}
      data-testid={dataTestId}
      disabled={disabled}
      id={id}
      isBtnRef={!!btnRef}
      onClick={onClick}
      ref={btnRef}
    >
      <FlexWrapper>
        {props.iconType === 'name' && (
          <Icon
            name={props.iconName}
            size={props.iconSize ?? 'sm'}
            weight={props.weight ?? 'regular'}
          />
        )}
        {props.iconType === 'svg' && (
          <SvgWrapper active={active || false}>
            <props.Svg />
          </SvgWrapper>
        )}
      </FlexWrapper>
      <TextWrapper active={active}>{label}</TextWrapper>
    </StyledButton>
  );
};

export default LabelToolbarButton;
