import { IconName } from '@fortawesome/fontawesome-svg-core';
import React from 'react';
import styled, { css } from 'styled-components';

import useIdRegistry from '../../../../hooks/useIdRegistry';
import { MenuOptionRegisteredId, ThreeDotMenuOptionRegisteredId } from '../../../../lib/idRegistry';
import { fontSm5 } from '../../../styled/TypeSystem';
import Icon from '../../display/icons/Icon';
import { IconWeight } from '../../display/icons/Icon/IconTypes';
import Tooltip from '../../display/Tooltip/Tooltip';
import P from '../../text/P';

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1rem;
  height: ${({ theme: { constants } }) => constants.height2xs};
  margin-right: ${({ theme: { constants } }) => constants.spacerMd2};
`;

const MenuOptionIcon = styled(Icon)`
  color: ${({ theme: { vars } }) => vars.textDefault};
`;

const DisabledMenuOptionIcon = styled(Icon)`
  color: ${({ theme: { vars } }) => vars.textDisabled};
`;

const DisabledCustomIconWrapper = styled.div`
  color: ${({ theme: { vars } }) => vars.textDisabled};
`;

export const MenuOptions = styled.ul`
  background: ${({ theme: { vars } }) => vars.foundationSurface1};
  list-style: none;
  white-space: nowrap;
  overflow: hidden;
  padding: ${({ theme: { constants } }) => constants.spacerSm3};
  z-index: 10;
  box-shadow: ${({ theme: { vars } }) => vars.shadowTopSmall};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusLg};
  border: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface2}`};
  margin: 0;
`;

export const StyledLi = styled.li`
  display: block;
  color: ${({ theme: { vars } }) => vars.textDefault};
  font-weight: ${({ theme: { constants } }) => constants.fontRegular};
  padding: ${({ theme: { constants } }) => `${constants.spacerSm3} ${constants.spacerMd2}`};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};

  &:hover {
    background: ${({ theme: { vars } }) => vars.foundationHover};
    cursor: pointer;
  }

  &:active {
    background: ${({ theme: { vars } }) => vars.foundationHover};
    color: ${({ theme: { vars } }) => vars.textDefault};

    svg {
      color: ${({ theme: { vars } }) => vars.textDefault};
    }
  }

  ${fontSm5};
`;
export const StyledDisabledLi = styled(StyledLi)`
  color: ${({ theme: { vars } }) => vars.textDisabled};
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};

  &:hover {
    background: ${({ theme: { vars } }) => vars.foundationSurface1};
    cursor: not-allowed;
  }

  &:active {
    svg {
      color: ${({ theme: { vars } }) => vars.textDisabled};
    }
  }
`;

const MenuOptionInner = styled.div`
  align-items: center;
  display: flex;
`;

const StyledDescription = styled(P)<{ isDisabled?: boolean }>(
  ({ isDisabled, theme: { constants, vars } }) => css`
    color: ${vars.textDisabled};
    color: ${isDisabled ? vars.textDisabled : vars.textSubdued} !important;
    margin-bottom: ${constants.spacerSm3};
    margin-left: ${constants.spacerLg1};
    white-space: initial;
    width: 14.875rem;
  `
);

const SharedTitle = styled.span<{ hasDescription: boolean }>`
  ${({ hasDescription, theme: { constants } }) =>
    hasDescription &&
    css`
      font-weight: ${constants.fontMedium};
    `};
`;

const Title = styled(SharedTitle)`
  color: ${({ theme: { vars } }) => vars.textDefault};
`;

const DisabledTitle = styled(SharedTitle)`
  color: ${({ theme: { vars } }) => vars.textDisabled};
`;

export interface Props {
  menuOptions: MenuOptionProps[];
}

type IconProps =
  | {
      customIcon?: never;
      iconName?: IconName;
      iconWeight?: IconWeight;
    }
  | {
      customIcon: React.ReactNode;
      iconName?: never;
      iconWeight?: never;
    };

export type MenuOptionProps = IconProps & {
  description?: string;
  title: string;
  onClick: ((e: React.MouseEvent) => void) | ((e: React.MouseEvent) => Promise<void>);
  id: ThreeDotMenuOptionRegisteredId | MenuOptionRegisteredId;
  className?: string;
  isDisabled?: boolean;
  tooltipText?: string;
};

export const MenuOption = ({
  description,
  title,
  onClick,
  customIcon,
  iconName,
  iconWeight,
  className,
  id,
  isDisabled,
  tooltipText,
}: MenuOptionProps) => {
  useIdRegistry(id);

  const onTrigger = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    e.stopPropagation();
    e.preventDefault();
    !isDisabled && onClick(e);
  };
  const dataForTooltip = tooltipText ? `tooltip-action-${title}` : '';

  return (
    <>
      {tooltipText && <Tooltip id={dataForTooltip} text={tooltipText} />}
      {isDisabled ? (
        <StyledDisabledLi
          className={className}
          data-for={dataForTooltip}
          data-tip
          id={id}
          onClick={onTrigger}
        >
          <MenuOptionInner>
            <IconWrapper>
              {customIcon && <DisabledCustomIconWrapper>{customIcon}</DisabledCustomIconWrapper>}
              {iconName && <DisabledMenuOptionIcon name={iconName} weight={iconWeight} />}
            </IconWrapper>
            <DisabledTitle hasDescription={!!description}>{title}</DisabledTitle>
          </MenuOptionInner>
          {description && <StyledDescription isDisabled text={description} />}
        </StyledDisabledLi>
      ) : (
        <StyledLi
          className={className}
          data-for={dataForTooltip}
          data-tip
          id={id}
          onClick={onTrigger}
        >
          <MenuOptionInner>
            <IconWrapper>
              {customIcon && customIcon}
              {iconName && <MenuOptionIcon name={iconName} weight={iconWeight} />}
            </IconWrapper>
            <Title hasDescription={!!description}>{title}</Title>
          </MenuOptionInner>
          {description && <StyledDescription text={description} />}
        </StyledLi>
      )}
    </>
  );
};
