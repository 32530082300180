import React, { ReactElement, ReactNode } from 'react';

import useCurrentAccount from '../hooks/useCurrentAccount';

type PaywallProviderProps = {
  children: ReactNode;
};

export const PaywallContext = React.createContext<string[]>([]);

const PaywallProvider = ({ children }: PaywallProviderProps): ReactElement => {
  const { paywalledFeatures } = useCurrentAccount();
  return <PaywallContext.Provider value={paywalledFeatures}>{children}</PaywallContext.Provider>;
};

export { PaywallProvider };
