import React from 'react';

import { useEditorContext } from '../../../../../../../contexts/EditorContext';
import initTranslations from '../../../../../../../lib/initTranslations';
import ToolbarButton from '../ToolbarButton';

const t = initTranslations('editor');

const CodeBlockToolbarButton = () => {
  const { editor } = useEditorContext();

  return (
    <ToolbarButton
      active={editor.isActive('codeBlock')}
      ariaLabel={t('aria_label_code')}
      className={editor.isActive('codeBlock') ? 'is-active' : ''}
      iconName='code'
      id='code-block-button'
      onClick={() => editor.chain().focus().toggleCodeBlock().run()}
      tooltipId='code-block-tooltip'
      tooltipText={t('code_block')}
    />
  );
};

export default CodeBlockToolbarButton;
