import styled from 'styled-components';

import { SkeletonLoaderProps } from './types';

export const SkeletonLoader = styled.div<SkeletonLoaderProps>`
  width: ${({ width }) => (width ? width : '100%')};
  ${({ minWidth }) => minWidth && `min-width: ${minWidth};`};
  height: ${({ height, theme: { constants } }) => (height ? height : constants.height2xs)};
  margin-top: ${({ marginTop }) => marginTop};
  margin-bottom: ${({ marginBottom }) => marginBottom};
  position: ${({ position }) => (position ? position : 'relative')};
  ${({ top }) => top && `top: ${top};`};
  ${({ right }) => right && `right: ${right};`};
  overflow: hidden;
  z-index: 1;
  background: ${({ theme: { vars } }) => vars.foundationBase2};
  ${({ withBorder, theme: { constants, vars } }) =>
    withBorder && `border: ${constants.borderWidthSm} solid ${vars.foundationBase1};`};
  border-radius: ${({ borderRadius, theme: { constants } }) =>
    borderRadius ? borderRadius : constants.borderRadiusEndcap};
  ${({ marginLeft }) => marginLeft && `margin-left: ${marginLeft};`};
  ${({ marginRight }) => marginRight && `margin-right: ${marginRight};`};

  @keyframes loading-skeleton {
    100% {
      transform: translateX(100%);
    }
  }

  &:after {
    content: ' ';
    position: absolute;
    left: 0;
    right: 0;
    height: 100%;
    background-repeat: no-repeat;
    background-image: ${({
      theme: {
        vars: { foundationBase1, foundationBase2 },
      },
    }) => `linear-gradient(90deg, ${foundationBase2}, ${foundationBase1}, ${foundationBase2})`};
    transform: translateX(-100%);
    animation-name: loading-skeleton;
    animation-direction: normal;
    animation-duration: 1.5s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
  }
`;

export const SkeletonLoaderWrapper = styled.div`
  position: relative;
`;
