import React from 'react';

import { useEditorContext } from '../../../../../../../contexts/EditorContext';
import initTranslations from '../../../../../../../lib/initTranslations';
import ToolbarButton from '../ToolbarButton';

const t = initTranslations('editor');

const UnlinkToolbarButton = () => {
  const { editor } = useEditorContext();
  return (
    <ToolbarButton
      active={false}
      ariaLabel={t('aria_label_unlink_button')}
      iconName='link-simple-slash'
      id='unlink-button'
      onClick={() => {
        editor.chain().focus().extendMarkRange('link').unsetLink().run();
      }}
      weight='regular'
    />
  );
};

export default UnlinkToolbarButton;
