import { AvatarSize } from './AvatarTypes';

export const getAvatarSquareStyles = (size: AvatarSize, borderRadius: string) => {
  let styles = {};

  switch (size) {
    case '2xs':
      styles = {
        height: '2.5rem',
        width: '2.5rem',
      };
      break;
    case 'xs':
      styles = {
        height: '3rem',
        width: '3rem',
      };
      break;
    case 'sm':
      styles = {
        height: '4rem',
        width: '4rem',
      };
      break;
    case 'md':
      styles = {
        height: '4.5rem',
        width: '4.5rem',
      };
      break;
    case 'lg':
      styles = {
        height: '6rem',
        width: '6rem',
      };
      break;
    case 'xl':
      styles = {
        height: '8rem',
        width: '8rem',
      };
      break;
    case '2xl':
      styles = {
        height: '11rem',
        width: '11rem',
      };
      break;
  }

  const squareStyles = {
    'border-radius': borderRadius,
    ...styles,
  };

  return squareStyles;
};
