import React from 'react';
import styled from 'styled-components';

import Icon from '../../../../../../design_system/display/icons/Icon';
import { fontMd1 } from '../../../../../../styled/TypeSystem';
import DropdownWithPoppableMenu from '../../../../../shared/DropdownWithPoppableMenu';
import { DropdownMenuItem } from '../../../../../shared/DropdownWithPoppableMenu/PoppableDropdownMenu/styles';
import { ToolbarStyledDropdown } from '../../../../../shared/DropdownWithPoppableMenu/styles';
import SingleSelectDropdownOption, { DropdownOptionProps } from './SingleSelectDropdownOption';

// DS override - background color needs to match DropdownMenuItem even though we are using a ToolbarStyledDropdown
// Removed spacing and height to match DropdownMenuItem for nested dropdowns
const OptionWrapper = styled.div`
  width: 100%;

  ${ToolbarStyledDropdown} {
    margin: 0;
    padding: 0;
    min-height: 0;
    color: ${({ theme: { vars } }) => vars.textDefault};
    background-color: ${({ theme: { vars } }) => vars.foundationSurface1} !important;
  }

  ${DropdownMenuItem}:hover & ${ToolbarStyledDropdown} {
    background-color: ${({ theme: { vars } }) => vars.foundationBase2} !important;
  }
`;

const Wrapper = styled.div`
  display: flex;
  gap: ${({ theme: { constants } }) => constants.spacerMd2};
  ${fontMd1};
`;

export type NestedOptionProps = Omit<DropdownOptionProps, 'iconName'>;

export type MultiSelectOptionProps = DropdownOptionProps & {
  nestedOptions: NestedOptionProps[];
};

const MultiSelectDropdownOption = ({
  iconName,
  text,
  onClick,
  nestedOptions,
}: MultiSelectOptionProps) => {
  return (
    <OptionWrapper onClick={onClick}>
      <DropdownWithPoppableMenu
        id='editor-ai-dropdown'
        menuId='nested-editor-ai-dropdown-menu'
        menuPlacement='right'
        options={nestedOptions.map((option, index) => {
          return (
            <SingleSelectDropdownOption
              key={option.text + index}
              nestedOption
              onClick={option.onClick}
              text={option.text}
            />
          );
        })}
        selectedOption={
          <Wrapper>
            {iconName && <Icon name={iconName} />}
            <span>{text}</span>
          </Wrapper>
        }
        setSelectedOption={() => ({})}
        style='toolbar'
      />
    </OptionWrapper>
  );
};

export default MultiSelectDropdownOption;
