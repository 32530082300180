import isTableHeadingSelected from '../../lib/isTableHeadingSelected';
import { isTableCellSelected } from '../../tiptap_extensions/table/utils';
import { ButtonConfig, ButtonStateProps } from './ButtonConfig';
import { ButtonTypes } from './ButtonTypes';
import AIDropdown from './components/AIDropdown/AIDropdown';
import AddImageToolbarButton from './components/format/AddImageToolbarButton';
import BlockQuoteToolbarButton from './components/format/BlockQuoteToolbarButton';
import BoldToolbarButton from './components/format/BoldToolbarButton';
import ClearFormattingToolbarButton from './components/format/ClearFormattingToolbarButton';
import CodeBlockToolbarButton from './components/format/CodeBlockToolbarButton';
import FontDropdownButton from './components/format/FontDropdownButton';
import FontSizeDropdownButton from './components/format/FontSizeDropdownButton';
import HeadingDropdownButton from './components/format/HeadingDropdownButton/HeadingDropdownButton';
import HighlighterToolbarButton from './components/format/HighlighterToolbarButton';
import IndentToolbarButton from './components/format/indent/IndentToolbarButton';
import OutdentToolbarButton from './components/format/indent/OutdentToolbarButton';
import ItalicToolbarButton from './components/format/ItalicToolbarButton';
import LineHeightDropdownButton from './components/format/LineHeightDropdownButton';
import OrderedListToolbarButton from './components/format/OrderedListToolbarButton';
import RedoToolbarButton from './components/format/RedoToolbarButton';
import StrikeToolbarButton from './components/format/StrikeToolbarButton';
import SubscriptToolbarButton from './components/format/SubscriptToolbarButton';
import SuperscriptToolbarButton from './components/format/SuperscriptToolbarButton';
import TextAlignDropdownButton from './components/format/TextAlignDropdownButton';
import {
  TextAlignCenterToolbarButton,
  TextAlignLeftToolbarButton,
  TextAlignRightToolbarButton,
} from './components/format/TextAlignToolbarButton';
import TextColorToolbarButton from './components/format/TextColorToolbarButton';
import UnderlineToolbarButton from './components/format/UnderlineToolbarButton';
import UndoToolbarButton from './components/format/UndoToolbarButton';
import UnorderedListToolbarButton from './components/format/UnorderedListToolbarButton';
import ListToolbarButton from './components/insert/ListToolbarButton';
import LinkToolbarButton from './components/links/LinkToolbarButton';
import OpenLinkToolbarButton from './components/links/OpenLinkToolbarButton';
import UnlinkToolbarButton from './components/links/UnlinkToolbarButton';
import AddColumnAfterButton from './components/table/AddColumnAfterButton';
import AddColumnBeforeButton from './components/table/AddColumnBeforeButton';
import AddRowAfterButton from './components/table/AddRowAfterButton';
import AddRowBeforeButton from './components/table/AddRowBeforeButton';
import CellBackgroundColorButton from './components/table/CellBackgroundColorButton';
import DeleteColumnButton from './components/table/DeleteColumnButton';
import DeleteRowButton from './components/table/DeleteRowButton';

const isContextBubble = ({ context }: ButtonStateProps) => context === 'bubble';
const isEmptySelection = ({ editor }: ButtonStateProps) => editor.view.state.selection.empty;

const NON_TEXTUAL_ELEMENTS = [
  'codeBlock',
  'link',
  'horizontalRule',
  'image',
  'iframe',
  'embedCard',
  'file',
  'video',
];

const isNonTextualSelection = ({ editor, alsoHideFor = [] }: ButtonStateProps) => {
  const allElementsToHideFor = [...NON_TEXTUAL_ELEMENTS, ...alsoHideFor];

  return allElementsToHideFor.some((element) => editor.isActive(element));
};

const isBubbleOrNonTextualSelection = (props: ButtonStateProps) => {
  const { editor } = props;
  const { view } = editor;

  return (
    (isContextBubble(props) && (isEmptySelection(props) || isNonTextualSelection(props))) ||
    isTableHeadingSelected({
      editor,
      view,
      state: view.state,
      from: view.state.selection.from,
      tableHeading: 'column',
    }) ||
    isTableHeadingSelected({
      editor,
      view,
      state: view.state,
      from: view.state.selection.from,
      tableHeading: 'row',
    })
  );
};

const ToolbarButtons: { [key in ButtonTypes]: ButtonConfig } = {
  addColumnAfter: {
    component: AddColumnAfterButton,
    shouldHide: ({ editor }) => {
      const { view } = editor;

      return !isTableHeadingSelected({
        editor,
        view,
        state: view.state,
        from: view.state.selection.from,
        tableHeading: 'column',
      });
    },
  },
  addColumnBefore: {
    component: AddColumnBeforeButton,
    shouldHide: ({ editor }) => {
      const { view } = editor;

      return !isTableHeadingSelected({
        editor,
        view,
        state: view.state,
        from: view.state.selection.from,
        tableHeading: 'column',
      });
    },
  },
  addImage: { component: AddImageToolbarButton },
  addRowAfter: {
    component: AddRowAfterButton,
    shouldHide: ({ editor }) => {
      const { view } = editor;

      return !isTableHeadingSelected({
        editor,
        view,
        state: view.state,
        from: view.state.selection.from,
        tableHeading: 'row',
      });
    },
  },
  addRowBefore: {
    component: AddRowBeforeButton,
    shouldHide: ({ editor }) => {
      const { view } = editor;

      return !isTableHeadingSelected({
        editor,
        view,
        state: view.state,
        from: view.state.selection.from,
        tableHeading: 'row',
      });
    },
  },
  blockQuote: { component: BlockQuoteToolbarButton },
  bold: {
    component: BoldToolbarButton,
    shouldHide: isBubbleOrNonTextualSelection,
  },
  inlineAI: {
    component: AIDropdown,
    shouldHide: isBubbleOrNonTextualSelection,
  },
  cellBackgroundColor: {
    component: CellBackgroundColorButton,
    shouldHide: ({ editor }) => {
      const { view } = editor;
      const selection = view.state.selection;
      const headerSelected =
        isTableHeadingSelected({
          editor,
          view,
          state: view.state,
          from: view.state.selection.from,
          tableHeading: 'row',
        }) ||
        isTableHeadingSelected({
          editor,
          view,
          state: view.state,
          from: view.state.selection.from,
          tableHeading: 'column',
        });

      const tableCellClicked = isTableCellSelected(selection) && !headerSelected;

      return !headerSelected && !tableCellClicked;
    },
  },
  clearFormatting: {
    component: ClearFormattingToolbarButton,
    shouldHide: isBubbleOrNonTextualSelection,
  },
  codeBlock: {
    component: CodeBlockToolbarButton,
    shouldHide: (props) => isBubbleOrNonTextualSelection({ ...props, alsoHideFor: ['listItem'] }),
  },
  deleteColumn: {
    component: DeleteColumnButton,
    shouldHide: ({ editor }) => {
      const { view } = editor;

      return !isTableHeadingSelected({
        editor,
        view,
        state: view.state,
        from: view.state.selection.from,
        tableHeading: 'column',
      });
    },
  },
  deleteRow: {
    component: DeleteRowButton,
    shouldHide: ({ editor }) => {
      const { view } = editor;

      return !isTableHeadingSelected({
        editor,
        view,
        state: view.state,
        from: view.state.selection.from,
        tableHeading: 'row',
      });
    },
  },
  editLink: {
    component: LinkToolbarButton,
    shouldHide: ({ editor }) => !editor.isActive('link'),
  },
  fontSize: { component: FontSizeDropdownButton },
  fontDropdown: { component: FontDropdownButton },
  heading: { component: HeadingDropdownButton },
  highlighter: { component: HighlighterToolbarButton },
  indent: { component: IndentToolbarButton },
  insertLink: { component: LinkToolbarButton, shouldHide: isBubbleOrNonTextualSelection },
  lists: { component: ListToolbarButton },
  italic: {
    component: ItalicToolbarButton,
    shouldHide: isBubbleOrNonTextualSelection,
  },
  lineHeight: { component: LineHeightDropdownButton },
  openLink: {
    component: OpenLinkToolbarButton,
    shouldHide: ({ editor }) => !editor.isActive('link'),
  },
  orderedList: { component: OrderedListToolbarButton },
  outdent: { component: OutdentToolbarButton },
  redo: { component: RedoToolbarButton, shouldHide: isContextBubble },
  strike: { component: StrikeToolbarButton },
  subscript: { component: SubscriptToolbarButton },
  superscript: { component: SuperscriptToolbarButton },
  textAlign: { component: TextAlignDropdownButton },
  textAlignCenter: { component: TextAlignCenterToolbarButton },
  textAlignLeft: { component: TextAlignLeftToolbarButton },
  textAlignRight: { component: TextAlignRightToolbarButton },
  textColor: { component: TextColorToolbarButton },
  underline: {
    component: UnderlineToolbarButton,
    shouldHide: isBubbleOrNonTextualSelection,
  },
  undo: { component: UndoToolbarButton, shouldHide: isContextBubble },
  unlink: { component: UnlinkToolbarButton, shouldHide: ({ editor }) => !editor.isActive('link') },
  unorderedList: { component: UnorderedListToolbarButton },
};

export default ToolbarButtons;
