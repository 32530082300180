import React from 'react';

import initTranslations from '../../../../../../../../lib/initTranslations';
import ToolbarButton from '../../ToolbarButton';
import { useHandleIndentClick } from './handleIndentClick';

const t = initTranslations('editor');

const OutdentToolbarButton = () => {
  const handleIndentClick = useHandleIndentClick();

  return (
    <ToolbarButton
      active={false}
      ariaLabel={t('aria_label_indent_right')}
      iconName='outdent'
      id='outdent-button'
      onClick={() => handleIndentClick('outdent')}
      tooltipId='outdent-tooltip'
      tooltipText={t('outdent')}
      weight='regular'
    />
  );
};

export default OutdentToolbarButton;
