import React from 'react';

import { useEditorContext } from '../../../../../../../contexts/EditorContext';
import initTranslations from '../../../../../../../lib/initTranslations';
import ToolbarButton from '../ToolbarButton';

const t = initTranslations('editor');

const UnderlineToolbarButton = () => {
  const { editor } = useEditorContext();

  return (
    <ToolbarButton
      active={editor.isActive('underline')}
      ariaLabel={t('aria_label_underline_button')}
      className={editor.isActive('underline') ? 'is-active' : ''}
      iconName='underline'
      id='underline-button'
      onClick={() => editor.chain().focus().toggleUnderline().run()}
      tooltipId='underline-tooltip'
      tooltipText={t('underline')}
    />
  );
};

export default UnderlineToolbarButton;
