import styled from 'styled-components';

import { mediaBreakpointPx2xl } from '../../../styled/Breakpoint';
import Scrollbar from '../../../styled/Scrollbar';
import { fontMd2, fontMd3 } from '../../../styled/TypeSystem';
import DefaultButton from '../../buttons/DefaultButton';
import IconButton from '../../buttons/IconButton';

export const FlyoutContainer = styled.div`
  box-shadow: ${({ theme: { vars } }) => vars.shadowTopSmall};
  border: ${({ theme: { constants } }) => constants.borderWidthSm} solid
    ${({ theme: { vars } }) => vars.borderSurface1};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};
  background: ${({ theme: { vars } }) => vars.foundationSurface1};
  width: 19.375rem;
  word-break: break-word;
`;

export const FlyoutHeader = styled.div<{ headerBackgroundColor: string | undefined }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme: { vars }, headerBackgroundColor }) =>
    headerBackgroundColor ? headerBackgroundColor : vars.foundationHover};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd}
    ${({ theme: { constants } }) => constants.borderRadiusMd} 0 0;
  padding: ${({ theme: { constants } }) => constants.spacerSm3}
    ${({ theme: { constants } }) => constants.spacerSm3}
    ${({ theme: { constants } }) => constants.spacerSm3}
    ${({ theme: { constants } }) => constants.spacerMd3};
  ${fontMd3};
`;

export const CloseIcon = styled(IconButton)`
  font-size: 1.75rem;
`;

export const FlyoutHeaderTitle = styled.span<{ headerTextColor: string | undefined }>`
  font-weight: ${({ theme: { constants } }) => constants.fontMedium};
  color: ${({ theme: { vars }, headerTextColor }) =>
    headerTextColor ? headerTextColor : vars.textDefault};
  ${fontMd2};
`;

export const FlyoutContent = styled.div<{ scrollable: boolean }>`
  max-height: ${({ scrollable }) => (scrollable ? '22rem' : '100%')};
  color: ${({ theme: { vars } }) => vars.textDefault};
  padding: ${({ theme: { constants } }) => `${constants.spacerMd2} ${constants.spacerMd3}`};
  overflow-y: ${({ scrollable }) => scrollable && 'auto'};
  ${Scrollbar};

  @media (min-width: ${mediaBreakpointPx2xl}) {
    max-height: ${({ scrollable }) => (scrollable ? '33rem' : '100%')};
  }
`;

export const FlyoutFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: ${({
    theme: {
      constants: { spacerMd2, spacerMd3 },
    },
  }) => ` 0 ${spacerMd3} ${spacerMd2} ${spacerMd3}`};
`;

export const FlyoutPrimaryButton = styled(DefaultButton)`
  margin-left: ${({ theme: { constants } }) => constants.spacerMd2};
`;

export const FlyoutPoppableContainer = styled.div`
  display: flex;
  position: relative;
  flex-shrink: 0;
`;
