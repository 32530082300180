import { OpenAITextModel } from '@tiptap-pro/extension-ai';
import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

import { useEditorContext } from '../../../../../../../contexts/EditorContext';
import usePrivateConfigs from '../../../../../../../hooks/usePrivateConfigs';
import DropdownWithPoppableMenu from '../../../../../shared/DropdownWithPoppableMenu';
import { ToolbarStyledDropdown } from '../../../../../shared/DropdownWithPoppableMenu/styles';
import { AIOptions, CombinedOptionProps } from './dropdownOptionArrays';
import MultiSelectDropdownOption, { MultiSelectOptionProps } from './MultiSelectDropdownOption';
import SingleSelectDropdownOption from './SingleSelectDropdownOption';
import SmartTools from './SmartTools';

// DS override - background color needs to stay the same when the menu is open and closed
const DropdownWrapper = styled.div`
  min-width: 9.25rem;
  margin-right: ${({ theme: { constants } }) => constants.spacerSm3};

  ${ToolbarStyledDropdown} {
    border-radius: ${({ theme: { constants } }) => constants.borderRadiusLg};
    background-color: ${({ theme: { vars } }) => vars.accentSubdued1};
  }
`;

const AIDropdown = () => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const handleDropdownToggle = useCallback((openState: boolean) => {
    setDropdownOpen(openState);
  }, []);

  const { configs: privateConfigs } = usePrivateConfigs();
  const { editor, isAILoading } = useEditorContext();

  const options = useMemo(() => {
    return AIOptions(editor, {
      stream: privateConfigs['TIPTAP_STREAMING_ENABLED'] === 'true',
      modelName: privateConfigs['TIPTAP_AI_MODEL_NAME'] as OpenAITextModel,
    });
  }, [editor, privateConfigs]);

  const isMultiSelectOptionProps = (
    option: CombinedOptionProps
  ): option is MultiSelectOptionProps => 'nestedOptions' in option;

  return (
    <DropdownWrapper>
      <DropdownWithPoppableMenu
        id='editor-ai-dropdown'
        lockDropdown={isAILoading}
        menuId='editor-ai-dropdown-menu'
        onDropdownToggle={handleDropdownToggle}
        options={options.map((option, index) => {
          if (isMultiSelectOptionProps(option)) {
            return (
              <MultiSelectDropdownOption
                iconName={option.iconName}
                nestedOptions={option.nestedOptions}
                onClick={option.onClick}
                text={option.text}
              />
            );
          }
          return (
            <SingleSelectDropdownOption
              iconName={option.iconName}
              key={option.text + index}
              nestedOption={false}
              onClick={option.onClick}
              text={option.text}
            />
          );
        })}
        selectedOption={<SmartTools isAILoading={isAILoading} isMenuOpen={isDropdownOpen} />}
        setSelectedOption={() => ({})}
        strategy='absolute'
        style='toolbar'
      />
    </DropdownWrapper>
  );
};

export default AIDropdown;
