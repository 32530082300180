import { BgColorsOutlined } from '@ant-design/icons';
import React from 'react';

import { ColorPickerIconInner, ColorPickerTextIconInner, TextIcon } from './styles';
import { ColorPickerTriggerProps } from './types';

const ColorPickerIcon = ({
  type,
  color,
}: Pick<ColorPickerTriggerProps, 'color' | 'type'>): JSX.Element => {
  switch (type) {
    case 'background-color':
      return <ColorPickerIconInner color={color} />;
    case 'cell-bg-color':
      return <BgColorsOutlined rev={undefined} style={{ fontSize: '1rem' }} />;
    default:
      return (
        <>
          <TextIcon
            name={type === 'highlighter-color' ? 'highlighter' : 'a'}
            size='xs'
            weight='regular'
          />
          <ColorPickerTextIconInner color={color} />
        </>
      );
  }
};

export default ColorPickerIcon;
