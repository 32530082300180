import Styles from 'react-select';
import { DefaultTheme } from 'styled-components';

import { Size } from '../../../../../types/design_system/Size';
import { SelectProp } from '../MultiSelectField/MultiSelectField';

export type ModalSize = Exclude<Size, '2xs' | 'xs' | 'xl' | '2xl'>;

export type SelectType = 'default' | 'secondary' | 'tertiary';
interface SelectProps extends DefaultTheme {
  size: ModalSize;
  isValid: boolean;
  errorText?: string;
  menuIsOpen: boolean;
  expandMenuToFitOptions: boolean;
  isDisabled: boolean;
  selectType: SelectType;
}
export interface StyleProps {
  isFocused: boolean;
  isSelected: boolean;
  isDisabled: boolean;
  data: {
    isSubOption: boolean;
  };
  hideSelectedOptions: boolean;
  selectProps: SelectProps;
}
export interface CustomProps {
  container: object | undefined;
  indicatorSeparator: object | undefined;
  control: object | undefined;
  menu: object | undefined;
  dropdownIndicator: object | undefined;
  clearIndicator: object | undefined;
  input: object | undefined;
  valueContainer: object | undefined;
  placeholder: object | undefined;
  menuList: object | undefined;
}
export const CustomStyle: typeof Styles | CustomProps = {
  container: (provided: object) => ({ ...provided }),

  indicatorSeparator: (provided: object) => ({ ...provided, display: 'none' }),

  control: (
    provided: object,
    {
      isFocused,
      isSelected,
      isDisabled,
      selectProps: { vars, size, constants, isValid, selectType, menuIsOpen, errorText },
    }: StyleProps
  ) => {
    const isHighlighted = isFocused || isSelected;
    const getSelectHeight = (size: ModalSize) => {
      switch (size) {
        case 'sm':
          return constants.heightSm;
        case 'md':
          return constants.heightMd;
        case 'lg':
          return constants.heightLg;
      }
    };

    const getTertiaryBgColor = () => {
      if (isDisabled) {
        return vars.foundationBase2;
      } else if (isHighlighted) {
        return vars.foundationBase1;
      } else {
        return 'transparent';
      }
    };

    const getBorderColor = ({ forHover = false }: { forHover?: boolean }) => {
      if (isDisabled) return vars.borderDisabled;
      if (!isValid || !!errorText) return vars.stateError;
      if (isHighlighted || forHover) return vars.accentPrimaryDefault;
      return vars.borderSurface2;
    };

    const getStylesFromType = () => {
      const commonBorderStyles = {
        border: `${constants.borderWidthSm} solid ${getBorderColor({})} !important`,
        '&:hover': {
          border: `${constants.borderWidthSm} solid ${getBorderColor({
            forHover: true,
          })} !important`,
        },
      };

      switch (selectType) {
        case 'secondary':
          return {
            backgroundColor: vars.foundationBase2,
            ...commonBorderStyles,
          };
        case 'tertiary':
          if (isHighlighted && !menuIsOpen) {
            return {
              backgroundColor: getTertiaryBgColor(),
              ...commonBorderStyles,
            };
          }

          return {
            backgroundColor: getTertiaryBgColor(),
            border: 'none',
          };
        case 'default':
          return {
            backgroundColor: isDisabled ? vars.foundationBase2 : vars.foundationSurface1,
            ...commonBorderStyles,
          };
      }
    };

    return {
      ...provided,
      cursor: isDisabled ? 'not-allowed' : 'pointer',
      pointerEvents: 'auto',
      minHeight: getSelectHeight(size),
      height: 'auto',
      boxShadow: '0',
      borderRadius: constants.borderRadiusLg,
      ...getStylesFromType(),
    };
  },

  menu: (
    provided: object,
    { selectProps: { expandMenuToFitOptions, vars, constants } }: StyleProps
  ) => ({
    ...provided,
    margin: '0',
    padding: constants.spacerSm3,
    backgroundColor: `${vars.foundationSurface1} !important`,
    zIndex: 2,
    borderRadius: constants.borderRadiusLg,
    ...(expandMenuToFitOptions && {
      width: 'max-content',
      minWidth: '100%',
    }),
  }),

  menuList: (provided: object) => ({
    ...provided,
    padding: '0',
  }),

  dropdownIndicator: (
    provided: object,
    { selectProps: { menuIsOpen, vars, isDisabled } }: StyleProps
  ) => ({
    ...provided,
    color: isDisabled ? vars.textDisabled : vars.textDefault,
    transform: menuIsOpen && 'rotate(180deg)',
    paddingTop: '0.3125rem',
    paddingBottom: '0.3125rem',
    '&:hover': {
      color: isDisabled ? vars.textDisabled : vars.textDefault,
    },
  }),

  clearIndicator: (provided: object, { selectProps: { constants, vars } }: SelectProp) => ({
    ...provided,
    color: vars.textDefault,
    paddingTop: constants.spacerSm2,
    paddingBottom: constants.spacerSm2,
    '&:hover': {
      color: vars.textDefault,
    },
  }),

  input: (provided: object) => ({
    ...provided,
    color: 'inherit',
    padding: '0',
    margin: '0',
  }),

  valueContainer: (provided: object, { selectProps: { constants } }: SelectProp) => ({
    ...provided,
    paddingLeft: constants.spacerMd1,
    paddingRight: 0,
  }),

  placeholder: (
    provided: { color: string },
    state: {
      isDisabled: boolean;
      selectProps: {
        vars: {
          textDisabled: boolean;
          textDefault: string;
          textPlaceholder: string;
        };
      };
    }
  ) => ({
    ...provided,
    color: state.isDisabled
      ? state.selectProps.vars.textDisabled
      : state.selectProps.vars.textPlaceholder,
    margin: '0',
  }),
};
