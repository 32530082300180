import { InsertRowAboveOutlined } from '@ant-design/icons';
import React from 'react';

import { useEditorContext } from '../../../../../../../contexts/EditorContext';
import initTranslations from '../../../../../../../lib/initTranslations';
import CustomToolBarButton from '../CustomToolbarButton/CustomToolbarButton';

const t = initTranslations('editor');

const AddRowBeforeButton = () => {
  const { editor } = useEditorContext();

  return (
    <CustomToolBarButton
      ariaLabel={t('aria_label_row_before_button')}
      customIcon={<InsertRowAboveOutlined rev={undefined} />}
      id='add-row-before-button'
      onClick={() => editor.chain().focus().addRowBefore().run()}
      tooltipId='add-row-before-button-tooltip'
      tooltipText={t('add_row_before')}
    />
  );
};

export default AddRowBeforeButton;
