import React, { ReactElement, ReactNode, useContext } from 'react';

type EditorFlyoutContext = {
  triggerBtnRef: React.RefObject<HTMLButtonElement>;
};

type EditorFlyoutProps = {
  children: ReactNode;
} & EditorFlyoutContext;

const EditorFlyoutContext = React.createContext({} as EditorFlyoutContext);
export const useEditorFlyoutContext = () => useContext(EditorFlyoutContext);

const EditorFlyoutProvider = ({ children, triggerBtnRef }: EditorFlyoutProps): ReactElement => {
  return (
    <EditorFlyoutContext.Provider
      value={{
        triggerBtnRef,
      }}
    >
      {children}
    </EditorFlyoutContext.Provider>
  );
};

export { EditorFlyoutProvider };
