import React from 'react';
import styled, { css } from 'styled-components';

import { TruncatedText } from '../../../../design_system/Triage/TruncatedText';
import { fontSm5 } from '../../../../styled/TypeSystem';
import { MenuItem } from '../MediaFlyoutMenu/MediaFlyoutMenu';

const activeMenuItemStyles = css`
  background-color: ${({ theme: { vars } }) => vars.foundationBase1};
  /* Important is needed here to override color set in base styles and needs to be removed with client side routing */
  color: ${({ theme: { vars } }) => vars.accentPrimaryDefault} !important;
`;

const FlyoutItemContainer = styled.div<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};
  width: 10rem;
  height: ${({ theme: { constants } }) => constants.heightMd};
  margin: ${({ theme: { constants } }) =>
    `-${constants.spacerSm3} 0 ${constants.spacerSm3} -${constants.spacerSm3}`};
  cursor: pointer;
  ${({ isActive }) => isActive && activeMenuItemStyles};

  div:not(#youtube, #vimeo, #loom) {
    svg {
      path {
        fill: ${({ theme: { vars }, isActive }) => isActive && vars.accentPrimaryDefault};

        // Important is needed here to override color set in base styles and needs to be removed with client side routing
        &:hover {
          fill: ${({ isActive, theme: { vars } }) => !isActive && `${vars.textDefault} !important`};
        }

        // Important is needed here to override color set in base styles and needs to be removed with client side routing
        &:active {
          fill: ${({ isActive, theme: { vars } }) => !isActive && `${vars.textDefault} !important`};
        }
      }
    }
  }

  &:hover {
    // Important is needed here to override color set in base styles and needs to be removed with client side routing
    a {
      color: ${({ isActive, theme: { vars } }) => !isActive && `${vars.textDefault} !important`};
    }
  }

  &:active {
    background-color: ${({ isActive, theme: { vars } }) => !isActive && `${vars.foundationBase2}`};

    // Important is needed here to override color set in base styles and needs to be removed with client side routing
    a {
      color: ${({ isActive, theme: { vars } }) => !isActive && `${vars.textDefault} !important`};
    }
  }
`;

const FlyoutItem = styled.button<{ isActive: boolean }>`
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  color: ${({ theme: { vars } }) => vars.textDefault} !important;
  font-weight: ${({ theme: { constants } }) => constants.fontRegular};

  /* Important is needed here to override color set in base styles and needs to be removed with client side routing */
  ${({ isActive, theme: { constants, vars } }) =>
    isActive &&
    `
      color: ${vars.accentPrimaryDefault} !important;
      font-weight: ${constants.fontMedium};
    `}

  ${fontSm5};
  ${TruncatedText({})};
`;

const FlyoutItemIcon = styled.div`
  svg {
    margin: 0 ${({ theme: { constants } }) => constants.spacerSm3};
    display: flex;
    justify-content: space-around;
  }
`;

export type Props = {
  item: MenuItem;
  onClick: () => void;
  isActive: boolean;
};

const MediaFlyoutMenuItem = ({ item: { SvgIcon, title, id }, onClick, isActive }: Props) => {
  return (
    <FlyoutItemContainer isActive={isActive} onClick={onClick}>
      <FlyoutItemIcon id={id}>
        <SvgIcon />
      </FlyoutItemIcon>
      <FlyoutItem isActive={isActive}>{title}</FlyoutItem>
    </FlyoutItemContainer>
  );
};

export default MediaFlyoutMenuItem;
