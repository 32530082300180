import styled from 'styled-components';

export type Direction = 'column' | 'row' | 'column-reverse' | 'row-reverse';

type Align =
  | 'baseline'
  | 'center'
  | 'end'
  | 'flex-end'
  | 'normal'
  | 'start'
  | 'stretch'
  | 'flex-start';

type JustifyContent =
  | 'flex-start'
  | 'flex-end'
  | 'center'
  | 'space-between'
  | 'space-around'
  | 'space-evenly'
  | 'initial'
  | 'inherit';

type FlexWrap = 'nowrap' | 'wrap' | 'wrap-reverse';

interface Props {
  direction?: Direction;
  align?: Align;
  justifyContent?: JustifyContent;
  gap?: string;
  flexWrap?: FlexWrap;
}

const FlexContainer = styled.div<Props>`
  display: flex;
  flex-flow: ${({ direction = 'row', flexWrap = 'nowrap' }) => `${direction} ${flexWrap}`};
  align-items: ${({ align = 'normal' }) => align};
  justify-content: ${({ justifyContent = 'flex-start' }) => justifyContent};
  gap: ${({ gap }) => gap};
`;

export default FlexContainer;
