import { ColorTokens } from 'saguaro';
import styled, { css } from 'styled-components';

import {
  getSectorBackgroundColor,
  getSectorBorderColor,
} from '../../../../lib/document_import/getSectorSelectionColors';
import { Sector } from '../../../../types/Sector';
import SingleSelectField from '../../../design_system/Triage/fields/SingleSelectField';
import { fontSm5 } from '../../../styled/TypeSystem';

export const StyledDropdown = styled.button<{ isOpen: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: ${({ theme: { constants } }) => constants.spacerSm3};
  border: ${({ theme: { constants } }) => constants.borderWidthSm} solid
    ${({ theme: { vars } }) => vars.borderSurface2};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusEndcap};
  font-weight: ${({ theme: { constants } }) => constants.fontRegular};
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
  color: ${({ theme: { vars } }) => vars.textDefault};
  cursor: pointer;

  &:hover,
  &:active,
  &:focus {
    border-color: ${({ theme: { vars } }) => vars.accentPrimaryDefault};
  }
  &:focus {
    outline: none;
  }
`;

export const ToolbarStyledDropdown = styled(StyledDropdown)`
  min-height: ${({ theme: { constants } }) => constants.heightSm};
  border: 0;
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};
  color: ${({ isOpen, theme: { vars } }) =>
    isOpen ? vars.accentPrimaryDefault : vars.textDefault};
  background-color: ${({ isOpen, theme: { vars } }) =>
    isOpen ? vars.accentSubdued1 : vars.foundationSurface1};

  :hover,
  :focus {
    background-color: ${({ isOpen, theme: { vars } }) =>
      isOpen ? vars.accentSubdued1 : vars.foundationHover};
  }
`;

export const FontSizeDropdown = styled(SingleSelectField)<{ isMenuOpen: boolean }>`
  min-height: ${({ theme: { constants } }) => constants.heightSm};

  .react-select__control {
    border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};
    background-color: ${({ isMenuOpen, theme: { vars } }) =>
      isMenuOpen ? vars.accentSubdued1 : vars.foundationSurface1};

    &:hover {
      background-color: ${({ isMenuOpen, theme: { vars } }) => !isMenuOpen && vars.foundationHover};
    }

    .react-select__single-value {
      color: ${({ isMenuOpen, theme: { vars } }) =>
        isMenuOpen ? vars.accentPrimaryDefault : vars.textDefault};
    }

    .react-select__indicator {
      color: ${({ isMenuOpen, theme: { vars } }) => isMenuOpen && vars.accentPrimaryDefault};
    }
  }
`;

export const SupershareStyledDropdown = styled(StyledDropdown)`
  min-height: ${({ theme: { constants } }) => constants.heightSm};
  border: 0;
  color: ${({ theme: { vars } }) => vars.textDefault};
  background-color: ${({ isOpen, theme: { vars } }) =>
    isOpen ? vars.foundationBase2 : vars.foundationSurface1};

  &:hover {
    color: ${({ isOpen, theme: { vars } }) => !isOpen && vars.textDefault};
    background-color: ${({ isOpen, theme: { vars } }) => !isOpen && vars.foundationHover};
  }
`;

export const GeneralAccessStyledDropdown = styled(StyledDropdown)(
  ({ isOpen, theme: { constants, vars } }) => css`
    background-color: ${isOpen ? vars.accentSubdued1 : vars.foundationSurface1};
    border: ${constants.borderWidthSm} solid ${vars.borderDefault};
    border-radius: ${constants.borderRadiusMd};
    padding: ${constants.spacerSm3} ${constants.spacerMd2};

    &:hover {
      background-color: ${!isOpen && vars.foundationHover};
    }
  `
);

export const SurveyStyledDropdown = styled(StyledDropdown)`
  min-height: ${({ theme: { constants } }) => constants.heightSm};
  border: 0;
  background: transparent;

  &:hover {
    color: ${({ isOpen, theme: { vars } }) => !isOpen && vars.textDefault};
    background-color: ${({ isOpen, theme: { vars } }) => !isOpen && vars.foundationBase1};
  }

  * {
    color: ${({ theme: { vars } }) => vars.textSubdued};
    font-weight: ${({ theme: { constants } }) => constants.fontMedium};

    svg {
      path {
        fill: ${({ theme: { vars } }) => vars.textSubdued} !important;
      }
    }
  }
`;

export const DelayTypeStyledDropdown = styled(StyledDropdown)(
  ({ theme: { constants, vars } }) => css`
    border: ${constants.borderWidthSm} solid ${vars.borderSurface2};
    border-radius: ${constants.borderRadiusLg};
    padding: ${constants.spacerSm3} ${constants.spacerMd2};
    gap: ${({ theme: { constants } }) => constants.spacerMd1};
    width: 6.75rem;

    ${fontSm5};
  `
);

const sectorDropdownStyle = (sector: Sector, vars: ColorTokens) => css`
  border-color: ${getSectorBorderColor(sector, vars)};
  background-color: ${getSectorBackgroundColor(sector, vars)};

  &:hover {
    background-color: ${getSectorBackgroundColor(sector, vars)};
  }
`;

export const SectorStyledDropdown = styled(StyledDropdown)`
  min-height: ${({ theme: { constants } }) => constants.heightSm};
  min-width: 10rem;
  background: transparent;

  &[aria-owns='upload-company-sector-selection-menu'] {
    ${({ theme: { vars } }) => sectorDropdownStyle('company', vars)}
  }

  &[aria-owns='upload-policy-sector-selection-menu'] {
    ${({ theme: { vars } }) => sectorDropdownStyle('policy', vars)}
  }

  &[aria-owns='upload-process-sector-selection-menu'] {
    ${({ theme: { vars } }) => sectorDropdownStyle('process', vars)}
  }
`;

export const DelegationBoardStyledDropdown = styled(StyledDropdown)`
  min-width: 18.5rem;
  max-width: 22rem;
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusLg};
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
`;

export const ESignaturePaywallBannerStyledDropdown = styled(StyledDropdown)`
  background: transparent;
  border: none;
  min-width: 5.75rem;
  color: ${({ theme: { vars } }) => vars.textSubdued};
  outline: none;
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.25rem;
  height: ${({ theme: { constants } }) => constants.height2xs};
`;

export const DropdownWrapper = styled.div`
  min-width: 7.5rem;
  margin: 0 ${({ theme: { constants } }) => constants.spacerSm2};
`;

export const SubjectModeDropdown = styled(StyledDropdown)`
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
`;
