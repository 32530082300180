import React, { ReactNode, createContext, useContext, useState } from 'react';

import { RegisteredMenuId } from '../lib/idRegistry';

interface ActiveMenuContext {
  activeMenuId: string | null;
  setActiveMenuId: React.Dispatch<React.SetStateAction<string | null>>;
}

type ActiveMenuProviderProps = {
  children: ReactNode;
};

const ActiveMenuContext = createContext({} as ActiveMenuContext);

// This should in the vast majority of cases only be imported for use in the handler. Look to see if 'useActiveMenuHandler'
// is what you need instead.
export const useActiveMenu = () => useContext(ActiveMenuContext);

export const ActiveMenuProvider = ({ children }: ActiveMenuProviderProps) => {
  const [activeMenuId, setActiveMenuId] = useState<RegisteredMenuId | null>(null);

  return (
    <ActiveMenuContext.Provider
      value={{
        activeMenuId,
        setActiveMenuId,
      }}
    >
      {children}
    </ActiveMenuContext.Provider>
  );
};
