import React, { ComponentClass, FunctionComponent, ReactNode } from 'react';
import ReactSelect, {
  ActionMeta,
  ControlProps,
  MenuPlacement,
  MenuPosition,
  MultiValueProps,
  OptionProps as ReactSelectOptionProps,
  ValueContainerProps,
  components,
} from 'react-select';
import styled, { useTheme } from 'styled-components';

import { Size } from '../../../../types/design_system/Size';
import {
  FontSize,
  FontSizeSelected,
  FontWeightSelected,
  FontWeightType,
} from '../../../application/people/Slideouts/Shared/FontSize';
import Scrollbar from '../../../styled/Scrollbar';
import Icon from '../../display/icons/Icon';
import Tooltip from '../../display/Tooltip/Tooltip';
import SourceBadge, { OptionalSourceProps } from '../../navigation/SourceBadge/SourceBadge';
import LoadingIndicator from '../../Triage/fields/SharedSelect/SelectLoadingIndicator/LoadingIndicator';
import { CustomStyle, SelectType } from '../../Triage/fields/SharedSelect/Styles';
import AssistiveText from '../AssistiveText';
import FieldLabel from '../FieldLabel';
import {
  DisabledFieldTooltipProps,
  LabelTooltipProps,
  TooltipProps,
} from '../Labels/SharedLabelTypes';
import SelectOption from '../SelectOption/SelectOption';
import { BaseOption, Option, OptionWithSourceBadgeProps } from '../SelectOption/types';

type ComponentType = FunctionComponent | ComponentClass;

const SelectWrapper = styled.div<{ fontSize: FontSize; fontWeight: FontWeightType }>`
  ${FontWeightSelected};
  ${FontSizeSelected};

  .react-select__menu-list {
    ${Scrollbar};
  }
`;

const StyledControlIcon = styled(Icon)`
  color: ${({ theme: { vars } }) => vars.textPlaceholder};
  pointer-events: none;
  margin-left: ${({ theme: { constants } }) => constants.spacerMd2};
`;

type SourceBadgeOptionsProps = {
  useSourceBadge: true;
  sourceBadgeOptions: OptionWithSourceBadgeProps[];
  options?: never;
};

type DefaultOptionsProps = {
  useSourceBadge?: never;
  sourceBadgeOptions?: never;
  options: Option[];
};
export type OptionsProps = DefaultOptionsProps | SourceBadgeOptionsProps;

export type Props = {
  onDefaultSelected?: () => void;
  onMenuClose?: () => void;
  onMenuOpen?: () => void;
  disabled?: boolean;
  loading?: boolean;
  isClearable?: boolean;
  isMenuOpenedOnFocus?: boolean;
  isMulti?: boolean;
  isSearchable?: boolean;
  isValid?: boolean;
  errorText?: string;
  className?: string;
  placeholder?: string;
  blurInputOnSelect?: boolean;
  closeMenuOnSelect?: boolean;
  tabSelectsValue?: boolean;
  size?: Size;
  name?: string;
  inputValue?: string;
  hideSelectedOptions?: boolean;
  OptionComponent?: ComponentType;
  MenuList?: ComponentType;
  fieldLabelText?: string;
  required?: boolean;
  htmlFor?: string;
  menuPlacement?: MenuPlacement;
  menuPosition?: MenuPosition;
  expandMenuToFitOptions?: boolean;
  fontSize?: FontSize;
  fontWeight?: FontWeightType;
  searchControlIcon?: boolean;
  selectType?: SelectType;
  isLocked?: boolean;
  assistiveText?: string;
} & LabelTooltipProps &
  DisabledFieldTooltipProps &
  OptionsProps;

export type CoreSelectFieldProps = Props & {
  componentStyles: object;
  getDefaultOption:
    | ((options: Option[]) => Option | undefined)
    | ((options: Option[]) => string | Option | Option[] | undefined);
  value: (Option | undefined) | (Option[] | undefined);
  handleSelectChanged: (option: Option | Option[] | null, actionMeta: ActionMeta<Option>) => void;
};

export const formatOptions = (options: BaseOption[]): Option[] => {
  return options.map((option) => ({
    label: option.label,
    value: option.value,
    searchableTerm: option.searchableTerm || option.label,
    hasSubOption: option.hasSubOption ?? false,
    isFixed: !!option.isFixed,
    disabled: option.disabled,
  }));
};

const formatOption = (
  option: { label: string; searchableTerm: string },
  { context }: { context: string }
) => {
  if (context === 'menu' || typeof option.label === 'string') return option.label;
  return option.searchableTerm;
};

const filterOptions = (
  option: { label: ReactNode | string; value: string; data: { searchableTerm: string } },
  input: string
) => {
  if (!input) return true;
  return option.data.searchableTerm.toLowerCase().includes(input.toLowerCase());
};

const SearchControl = (props: ControlProps) => {
  return (
    <components.Control {...props}>
      <StyledControlIcon name='search' weight='regular' />
      {props.children}
    </components.Control>
  );
};

const StyledValueContainer = styled.div`
  margin: ${({ theme: { constants } }) => `${constants.spacerSm2} 0`};
  a {
    margin: ${({ theme: { constants } }) =>
      `${constants.spacerSm2} ${constants.spacerSm3} ${constants.spacerSm2} 0`};
  }
`;

const LockedValueWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme: { constants } }) => constants.spacerSm1};
`;

const LockedMultiValueLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusXl};
  background: ${({ theme: { vars } }) => vars.stateBadgeGeneral};
  color: ${({ theme: { vars } }) => vars.textDefault};
  gap: ${({ theme: { constants } }) => constants.spacerSm2};
  padding: ${({ theme: { constants } }) => `${constants.spacerSm2} ${constants.spacerSm3}`};
`;

const LockedValueContainer = ({ children, ...props }: ValueContainerProps) => {
  return (
    <StyledValueContainer>
      <components.ValueContainer {...props}>
        <LockedValueWrapper>
          {children}
          <Icon name='lock' size='2xs' />
        </LockedValueWrapper>
      </components.ValueContainer>
    </StyledValueContainer>
  );
};

const LockedMultiValueLabel = (props: MultiValueProps<Option>) => {
  return (
    <components.MultiValueLabel {...props}>
      <LockedMultiValueLabelWrapper>
        {props.children}
        <Icon name='lock' size='2xs' />
      </LockedMultiValueLabelWrapper>
    </components.MultiValueLabel>
  );
};

const CustomValueContainer = ({ children, ...props }: ValueContainerProps) => {
  return (
    <StyledValueContainer>
      <components.ValueContainer {...props}>{children}</components.ValueContainer>
    </StyledValueContainer>
  );
};

type CustomSourceBadgeProps = MultiValueProps<OptionWithSourceBadgeProps>;

const CustomSourceBadge = (props: CustomSourceBadgeProps) => {
  const { data, removeProps } = props;
  const handleRemoveClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (removeProps && removeProps.onClick) {
      removeProps.onClick(event);
    }
  };

  const optionalSourceProps: OptionalSourceProps =
    data.sourceName === 'users'
      ? { sourceName: data.sourceName, sourceImageUrl: data.avatarImage }
      : { sourceName: data.sourceName };

  return (
    <SourceBadge
      id={`source-badge-user-${data.value}`}
      readOnly
      removable
      removeAction={handleRemoveClick}
      sourceSubtext={data.sourceSubtext}
      sourceText={data.avatarName || ''}
      {...optionalSourceProps}
    />
  );
};

const CustomOption = (props: ReactSelectOptionProps & { data: Option }) => {
  const { data, innerRef, innerProps, isSelected, isFocused } = props;
  return (
    <div
      ref={innerRef}
      {...innerProps}
      // Added 'selected-option-container' to scroll to this class when 'onMenuOpen' event fires
      // (https://github.com/JedWatson/react-select/issues/3648)
      className={isSelected ? 'selected-option-container' : ''}
      style={{ pointerEvents: data.disabled ? 'none' : 'auto' }}
    >
      <SelectOption
        avatarImage={data.avatarImage}
        avatarName={data.avatarName}
        disabled={data.disabled}
        hasAvatar={data.hasAvatar}
        hasMetaValue={data.hasMetaValue}
        hasSubOption={data.hasSubOption}
        isFocused={isFocused}
        label={data.label}
        metaValue={data.metaValue}
        searchableTerm={data.searchableTerm}
        selectedOptionsVisible={isSelected}
        size={data.size}
        value={data.value}
      />
    </div>
  );
};

const CoreSelectField = (props: CoreSelectFieldProps) => {
  const {
    componentStyles,
    getDefaultOption,
    handleSelectChanged,
    fieldLabelText,
    placeholder,
    blurInputOnSelect,
    className = '',
    disabled = false,
    loading = false,
    isClearable = false,
    isMenuOpenedOnFocus = false,
    isMulti = false,
    isSearchable = true,
    isValid = true,
    errorText,
    closeMenuOnSelect = true,
    onMenuClose,
    onMenuOpen,
    tabSelectsValue = true,
    size = 'md',
    name,
    hideSelectedOptions = true,
    OptionComponent,
    MenuList,
    required = false,
    htmlFor,
    labelTooltipText,
    labelTooltipId,
    disabledFieldTooltipText,
    disabledFieldTooltipId,
    value,
    menuPlacement = 'auto',
    menuPosition = 'absolute',
    expandMenuToFitOptions = false,
    fontSize = 'sm5',
    fontWeight = 'regular',
    searchControlIcon = false,
    useSourceBadge,
    selectType = 'default',
    isLocked = false,
    assistiveText,
  } = props;

  const options = useSourceBadge ? props.sourceBadgeOptions : props.options;

  const theme = useTheme();
  const styles = { ...CustomStyle, ...componentStyles };
  let components: {
    ClearIndicator?: ComponentType;
    LoadingIndicator: ComponentType;
    Option?: ComponentType;
    DropdownIndicator?: ComponentType;
    Control?: ComponentType;
    MenuList?: ComponentType;
    MultiValue?: (props: { data: Option }) => JSX.Element;
    MultiValueContainer?: ComponentType;
    ValueContainer?: ComponentType;
  } = { LoadingIndicator };

  if (OptionComponent) {
    components = { ...components, Option: OptionComponent };
  }

  if (searchControlIcon) {
    components = {
      ...components,
      Control: SearchControl,
    };
  }

  if (MenuList) {
    components = { ...components, MenuList };
  }

  if (useSourceBadge) {
    components = {
      ...components,
      ClearIndicator: () => null,
      DropdownIndicator: () => null,
      MultiValue: CustomSourceBadge,
      ValueContainer: CustomValueContainer,
    };
  }

  if (isLocked) {
    isMulti
      ? (components = {
          ...components,
          MultiValue: LockedMultiValueLabel,
        })
      : (components = {
          ...components,
          ValueContainer: LockedValueContainer,
        });
  }

  components = { ...components, Option: CustomOption };

  const ReactSelectProps: object = {
    blurInputOnSelect,
    className,
    classNamePrefix: 'react-select',
    closeMenuOnSelect,
    components,
    constants: theme.constants,
    defaultValue: getDefaultOption(options),
    expandMenuToFitOptions,
    filterOption: filterOptions,
    formatOptionLabel: formatOption,
    // Added 'onMenuOpen' property to scroll to the selected option when this event fires
    // (https://github.com/JedWatson/react-select/issues/3648)
    onMenuOpen,
    hideSelectedOptions,
    isClearable,
    isDisabled: disabled,
    selectType,
    isLoading: loading,
    isMulti,
    isSearchable,
    isValid,
    errorText,
    menuPlacement,
    menuPosition,
    // Dropdown remains fixed in place and doesn't scroll with parent container
    // https://github.com/JedWatson/react-select/issues/4088
    menuShouldBlockScroll: menuPosition === 'fixed',
    name,
    onChange: handleSelectChanged,
    onMenuClose,
    openMenuOnFocus: isMenuOpenedOnFocus,
    options,
    placeholder,
    size,
    styles,
    tabSelectsValue,
    value: value || null,
    vars: theme.vars,
  };

  const labelTooltipProps: TooltipProps =
    labelTooltipId && labelTooltipText
      ? { tooltipId: labelTooltipId, tooltipText: labelTooltipText }
      : {};

  return (
    <>
      {fieldLabelText && (
        <FieldLabel
          htmlFor={htmlFor}
          required={required}
          text={fieldLabelText}
          {...labelTooltipProps}
        />
      )}

      <SelectWrapper fontSize={fontSize} fontWeight={fontWeight}>
        {disabled && disabledFieldTooltipId && disabledFieldTooltipText ? (
          <div data-for={disabledFieldTooltipId} data-tip='true' id={disabledFieldTooltipId}>
            <Tooltip id={disabledFieldTooltipId} text={disabledFieldTooltipText} />
            <ReactSelect {...ReactSelectProps} />
          </div>
        ) : (
          <ReactSelect {...ReactSelectProps} />
        )}
        {(!!errorText || assistiveText) && (
          <AssistiveText
            id={`input-${errorText ? 'error' : 'help'}-text`}
            text={errorText || assistiveText}
            type={errorText ? 'error' : 'help'}
          />
        )}
      </SelectWrapper>
    </>
  );
};

export default CoreSelectField;
