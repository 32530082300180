import { ColorTokens } from 'saguaro';

import { Sector } from '../../types/Sector';

export function getSecondarySectorColor(sector: Sector, vars: ColorTokens) {
  switch (sector) {
    case 'company':
      return vars.trainualBrandYellowMedium;
    case 'policy':
      return vars.trainualBrandMagentaSubdued;
    case 'process':
      return vars.trainualBrandBlueSubdued;
    default:
      return vars.trainualBrandPurpleSubdued;
  }
}

export function getPrimarySectorColor(sector: Sector, vars: ColorTokens) {
  switch (sector) {
    case 'company':
      return vars.trainualBrandYellowStrong;
    case 'policy':
      return vars.trainualBrandMagentaStrong;
    case 'process':
      return vars.trainualBrandBlueStrong;
    default:
      return vars.trainualBrandPurpleStrong;
  }
}

export function getSectorBackgroundColor(sector: Sector, vars: ColorTokens) {
  switch (sector) {
    case 'company':
      return vars.trainualBrandYellowSurfaceLight;
    case 'policy':
      return vars.trainualBrandMagentaSurfaceLight;
    case 'process':
      return vars.trainualBrandBlueSurfaceLight;
    default:
      return vars.trainualBrandPurpleSurfaceLight;
  }
}

export function getSectorBorderColor(sector: Sector, vars: ColorTokens) {
  switch (sector) {
    case 'company':
      return vars.trainualBrandYellowStrong;
    case 'policy':
      return vars.trainualBrandMagentaStrong;
    case 'process':
      return vars.trainualBrandBlueStrong;
    default:
      return vars.trainualBrandPurpleSubdued;
  }
}
