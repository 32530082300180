import { ContentStyles } from '../../../app/javascript/react/types/ContentStyle';

export const customContentStyles: ContentStyles = {
  a: {
    color: '#6823b7',
    fontSize: '16px',
    fontStyle: 'normal',
    textAlign: 'left',
    fontFamily: 'Poppins',
    fontWeight: 'normal',
    textDecoration: 'none',
  },
  p: {
    color: '#38D4AE',
    fontSize: '14px',
    fontStyle: 'normal',
    textAlign: 'left',
    fontFamily: 'Times new Roman',
    fontWeight: 'normal',
    textDecoration: 'none',
  },
  h1: {
    color: '#6BC8EA',
    fontSize: '48px',
    fontStyle: 'normal',
    textAlign: 'center',
    fontFamily: 'Bodoni Moda',
    fontWeight: '900',
    textDecoration: 'underline',
  },
  h2: {
    color: '#6A28EA',
    fontSize: '24px',
    fontStyle: 'normal',
    textAlign: 'left',
    fontFamily: 'Oswald',
    fontWeight: '700',
    textDecoration: 'none',
  },
  h3: {
    color: '#020044',
    fontSize: '18px',
    fontStyle: 'normal',
    textAlign: 'right',
    fontFamily: 'Muli',
    fontWeight: '600',
    textDecoration: 'none',
  },
  h4: {
    color: '#252430',
    fontSize: '18px',
    fontStyle: 'normal',
    textAlign: 'center',
    fontFamily: 'Poppins',
    fontWeight: 'normal',
    textDecoration: 'none',
  },
  hr: { borderColor: '#38D4AE', borderWidth: '4px' },
};

export const defaultContentStyles: ContentStyles = {
  a: {
    color: '#00b3e2',
    fontSize: '16px',
    fontStyle: 'normal',
    textAlign: 'left',
    fontFamily: 'Poppins',
    fontWeight: 'normal',
    textDecoration: 'none',
  },
  p: {
    color: '#494D55',
    fontSize: '16px',
    fontStyle: 'normal',
    textAlign: 'left',
    fontFamily: 'Poppins',
    fontWeight: 'normal',
    textDecoration: 'none',
  },
  h1: {
    color: '#494D55',
    fontSize: '32px',
    fontStyle: 'normal',
    textAlign: 'left',
    fontFamily: 'Poppins',
    fontWeight: 'normal',
    textDecoration: 'none',
  },
  h2: {
    color: '#494D55',
    fontSize: '24px',
    fontStyle: 'normal',
    textAlign: 'left',
    fontFamily: 'Poppins',
    fontWeight: 'normal',
    textDecoration: 'none',
  },
  h3: {
    color: '#494D55',
    fontSize: '18px',
    fontStyle: 'normal',
    textAlign: 'left',
    fontFamily: 'Poppins',
    fontWeight: 'normal',
    textDecoration: 'none',
  },
  h4: {
    color: '#494D55',
    fontSize: '16px',
    fontStyle: 'normal',
    textAlign: 'left',
    fontFamily: 'Poppins',
    fontWeight: 'normal',
    textDecoration: 'none',
  },
  hr: { borderColor: '#eaeaf1', borderWidth: '1px' },
};
