import { Editor } from '@tiptap/core';
import React, { Fragment, ReactElement } from 'react';
import styled from 'styled-components';

import { useEditorContext } from '../../../../contexts/EditorContext';
import { useEditorToolbarContext } from '../../../../contexts/EditorToolbarContext';
import { ToolbarLockedOverlay } from '../shared/styles';
import ToolbarButtons from './buttons';
import { ButtonTypes } from './buttons/ButtonTypes';

export type Button = ButtonTypes;

export type ButtonGroup = {
  name: string;
  buttons: Button[];
};

const UndoRedo: ButtonGroup = { name: 'UndoRedo', buttons: ['undo', 'redo'] };
const Heading: ButtonGroup = { name: 'Heading', buttons: ['heading'] };
const Link: ButtonGroup = { name: 'Link', buttons: ['insertLink'] };
const AdvancedTextTools: ButtonGroup = {
  name: 'AdvancedTextTools',
  buttons: ['subscript', 'superscript', 'codeBlock'],
};
const Misc: ButtonGroup = { name: 'Misc', buttons: ['clearFormatting'] };

const TextFormatting: ButtonGroup = {
  name: 'TextFormatting',
  buttons: ['textAlign', 'lineHeight', 'lists', 'blockQuote', 'indent', 'outdent'],
};

// The simple naming here is because it is used for ToolbarSimple below.
const simpleTextAlign: ButtonGroup = {
  name: 'TextAlign',
  buttons: ['textAlignLeft', 'textAlignCenter', 'textAlignRight', 'unorderedList', 'orderedList'],
};

const simpleFontStyles: ButtonGroup = {
  name: 'FontStyles',
  buttons: ['bold', 'italic', 'underline', 'strike'],
};
const commonFontStyles: ButtonGroup = {
  name: 'CommonFontStyles',
  buttons: [...simpleFontStyles.buttons, 'highlighter'],
};
const unlockedFontStyles: ButtonGroup = {
  name: 'FontStyles',
  buttons: [
    'fontDropdown',
    'fontSize',
    ...commonFontStyles.buttons.filter((button) => button !== 'highlighter'),
    'textColor',
    'highlighter',
  ],
};

export const ToolbarSimple: ButtonGroup[] = [UndoRedo, simpleFontStyles, simpleTextAlign];

export const ToolbarAll: ButtonGroup[] = [
  UndoRedo,
  Heading,
  unlockedFontStyles,
  Link,
  TextFormatting,
  AdvancedTextTools,
  Misc,
];

export const LockedToolbar: ButtonGroup[] = [
  UndoRedo,
  Heading,
  commonFontStyles,
  Link,
  TextFormatting,
  AdvancedTextTools,
  Misc,
];

const RichTextareaFontStyles: ButtonGroup = {
  name: 'RichTextareaFontStyles',
  buttons: ['fontDropdown', 'fontSize', 'bold', 'italic', 'underline', 'textColor'],
};

const RichTextareaAlignmentAndList: ButtonGroup = {
  name: 'RichTextareaAlignmentAndList',
  buttons: ['textAlignLeft', 'textAlignCenter', 'textAlignRight', 'unorderedList'],
};

export const WithInsertDropdownToolbar: ButtonGroup[] = [
  UndoRedo,
  Heading,
  RichTextareaFontStyles,
  RichTextareaAlignmentAndList,
];

export const ToolbarContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: left;
  padding: ${({ theme: { constants } }) => constants.spacerSm2};
`;

export const Divider = styled.div`
  height: ${({ theme: { constants } }) => constants.spacerMd3};
  margin: 0 ${({ theme: { constants } }) => constants.spacerSm2};

  :not(:last-child) {
    border-right: ${({ theme: { constants, vars } }) =>
      `${constants.borderWidthSm} solid ${vars.borderSurface2}`};
  }
`;

export const ButtonComponent = ({
  buttonName,
}: {
  buttonName: ButtonTypes;
  editor: Editor;
}): ReactElement => {
  return ToolbarButtons[buttonName].component();
};

export type ToolbarProps = {
  className?: string;
};

const Toolbar = ({ className = '' }: ToolbarProps) => {
  const { activeButtons, context } = useEditorToolbarContext();
  const { editor, isEditingTitle } = useEditorContext();

  return (
    <ToolbarContainer className={`editor_toolbar_${context} ${className}`}>
      {isEditingTitle && <ToolbarLockedOverlay className='toolbar-locked-overlay' />}
      {activeButtons.map((buttonGroup, index) => (
        <Fragment key={`${buttonGroup.name}`}>
          {buttonGroup.buttons.map((button) => (
            <ButtonComponent buttonName={button} editor={editor} key={button} />
          ))}
          {index !== activeButtons.length - 1 && <Divider />}
        </Fragment>
      ))}
    </ToolbarContainer>
  );
};

export default Toolbar;
