import styled from 'styled-components';

export const FormGroup = styled.div<{ disablePointer?: boolean }>`
  pointer-events: ${({ disablePointer }) => (disablePointer ? 'none' : 'auto')};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
  user-select: none;
  &:last-child {
    margin-bottom: 0;
  }
`;
