import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { RegisteredMenuId } from '../../../lib/idRegistry';
import { Strategy } from '../../Poppable';
import Flyout from '../overlays/flyout';

// DS override: The flyout width is hard-coded to 19.375rem
// so to avoid the children from overflowing, we need to set the width to fit-content
const StyledFlyout = styled(Flyout)`
  width: fit-content;
`;

export type Props = {
  flyoutId: RegisteredMenuId;
  children: ReactNode;
  trigger: JSX.Element;
  strategy?: Strategy;
};

const ColorPicker = ({ flyoutId, children, trigger, strategy }: Props) => {
  return (
    <StyledFlyout
      fullWidthButtons
      hideHeader
      id={flyoutId}
      placement='bottom-start'
      strategy={strategy}
      triggerButton={trigger}
    >
      {children}
    </StyledFlyout>
  );
};

export default ColorPicker;
