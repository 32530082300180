import styled, { css } from 'styled-components';

import { TextSwatchVariations } from './types';

export const SwatchesWrapper = styled.div<{ rowCount: number }>`
  display: grid;
  grid-template-columns: repeat(${({ rowCount }) => rowCount}, 1fr);
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
`;

export const TextSwatchContainer = styled.button<{
  isActiveColor: boolean;
  variant: TextSwatchVariations;
  color: string;
}>`
  display: flex;
  width: 2.5rem;

  ${({ theme: { vars, constants }, isActiveColor, variant, color }) => {
    let borderStyle;

    if (isActiveColor) {
      borderStyle = `${constants.borderWidthLg} solid ${vars.borderDisabled}`;
    } else if (variant === 'text-color') {
      borderStyle = `${constants.borderWidthSm} solid ${vars.borderSurface2}`;
    } else {
      borderStyle = 'none';
    }

    return css`
      border: ${borderStyle};
      border-radius: ${constants.borderRadiusLg};
      height: ${constants.heightLg};
      margin: 0 ${constants.spacerSm3} ${constants.spacerSm3} 0;
      background-color: ${variant === 'highlight-color' ? color : vars.foundationSurface1};
    `;
  }}

  &:hover {
    cursor: pointer;
  }
`;
