import { AvatarSize } from './AvatarTypes';

export const getAvatarCircleStyles = (size: AvatarSize, borderRadius: string) => {
  let styles = {};

  switch (size) {
    case '2xs':
      styles = {
        height: '1rem',
        width: '1rem',
      };
      break;
    case 'xs':
      styles = {
        height: '1.5rem',
        width: '1.5rem',
      };
      break;
    case 'sm':
      styles = {
        height: '2rem',
        width: '2rem',
      };
      break;
    case 'md':
      styles = {
        height: '2.5rem',
        width: '2.5rem',
      };
      break;
    case 'lg':
      styles = {
        height: '3rem',
        width: '3rem',
      };
      break;
    case 'xl':
      styles = {
        height: '4rem',
        width: '4rem',
      };
      break;
    case '2xl':
      styles = {
        height: '8rem',
        width: '8rem',
      };
      break;
  }

  const circleStyles = {
    'border-radius': borderRadius,
    ...styles,
  };

  return circleStyles;
};
