import React from 'react';
import styled, { DefaultTheme, css } from 'styled-components';

import { fontSm3, fontSm5 } from '../../../styled/TypeSystem';
import Avatar from '../../display/avatar';
import Icon from '../../display/icons/Icon';
import { Option, OptionSize } from './types';

const Option = styled.div<{
  size: OptionSize;
  disabled?: boolean;
  hasSubOption: boolean;
  isFocused: boolean;
}>`
  display: flex;
  align-items: center;
  padding-right: ${({ theme: { constants } }) => constants.spacerMd2};
  padding-left: ${({ hasSubOption, theme: { constants } }) =>
    hasSubOption ? constants.spacerLg1 : constants.spacerMd2};
  ${({ size, theme }) => getOptionSize(size, theme)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};

  ${({ isFocused }) =>
    isFocused &&
    css`
      background-color: ${({ theme: { vars } }) => vars.foundationHover};
    `};
`;

const OptionContainer = styled.div<{ selectedOptionsVisible?: boolean; hasAvatar: boolean }>`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: ${({ hasAvatar }) => (hasAvatar ? 'calc(100% - 2rem)' : '100%')};
  position: relative;
  padding-right: ${({ selectedOptionsVisible, theme: { constants } }) =>
    selectedOptionsVisible ? constants.spacerMd3 : 0};
`;

const AvatarStyled = styled(Avatar)`
  margin-right: ${({ theme: { constants } }) => constants.spacerSm3};
`;

const Value = styled.div<{ disabled?: boolean; labelIsString: boolean }>`
  color: ${({ disabled, theme: { vars } }) => (disabled ? vars.textDisabled : vars.textDefault)};
  font-weight: ${({ theme: { constants } }) => constants.fontRegular};
  word-wrap: break-word;
  ${({ disabled, labelIsString }) =>
    disabled &&
    css`
      opacity: ${labelIsString ? 1 : 0.6};
    `};

  ${fontSm5};
`;

const MetaValue = styled.div<{ disabled?: boolean }>`
  color: ${({ disabled, theme: { vars } }) => (disabled ? vars.textDisabled : vars.textSubdued)};
  font-weight: ${({ theme: { constants } }) => constants.fontRegular};
  word-wrap: break-word;
  ${fontSm3};
`;

const IconStyled = styled(Icon)`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  color: ${({ theme: { vars } }) => vars.stateInfo};
`;

const getOptionSize = (size: OptionSize, theme: DefaultTheme) => {
  const {
    constants: { heightMd, heightLg, spacerSm2, spacerSm3 },
  } = theme;
  switch (size) {
    case 'md':
      return {
        'min-height': heightMd,
        'padding-top': spacerSm2,
        'padding-bottom': spacerSm2,
      };
    case 'lg':
      return {
        'min-height': heightLg,
        'padding-top': spacerSm3,
        'padding-bottom': spacerSm3,
      };
  }
};

const SelectOption = (props: Option) => {
  const {
    disabled = false,
    hasAvatar = false,
    avatarImage,
    avatarName = '',
    size = 'md',
    hasMetaValue = true,
    metaValue,
    label,
    selectedOptionsVisible = false,
    hasSubOption = false,
    isFocused = false,
  } = props;

  return (
    <Option disabled={disabled} hasSubOption={hasSubOption} isFocused={isFocused} size={size}>
      {hasAvatar && <AvatarStyled image={avatarImage} name={avatarName} shape='circle' size='sm' />}
      <OptionContainer hasAvatar={hasAvatar} selectedOptionsVisible={selectedOptionsVisible}>
        <Value
          className='react-select__option'
          disabled={disabled}
          labelIsString={typeof label === 'string'}
        >
          {label}
        </Value>
        {!!metaValue && hasMetaValue && <MetaValue disabled={disabled}>{metaValue}</MetaValue>}
        {selectedOptionsVisible && <IconStyled name='check' />}
      </OptionContainer>
    </Option>
  );
};

export default SelectOption;
