import { IconName } from '@fortawesome/fontawesome-svg-core';
import React from 'react';

import initTranslations from '../../../../../../../lib/initTranslations';
import LabelToolbarButton from '../LabelToolbarButton';
import ToolbarButton from '../ToolbarButton';

const t = initTranslations('editor.image');

interface Props {
  active: boolean;
  showLabel?: boolean;
  onClick?: () => void;
}

const AddImageToolbarButton = ({ active, onClick, showLabel = true }: Props) => {
  const baseButtonProps = {
    active,
    ariaLabel: t('aria_label_image_button'),
    className: 'flyout-trigger',
    dataTestId: 'image-flyout-button',
    id: 'image-flyout-button',
    iconName: 'image' as IconName,
    tooltipId: 'image-tooltip',
    tooltipText: t('image'),
    onClick,
  };

  return showLabel ? (
    <LabelToolbarButton {...baseButtonProps} iconType='name' label={t('image')} />
  ) : (
    <ToolbarButton {...baseButtonProps} weight='regular' />
  );
};

export default AddImageToolbarButton;
