import React from 'react';

import { useEditorContext } from '../../../../../../../contexts/EditorContext';
import initTranslations from '../../../../../../../lib/initTranslations';
import ToolbarButton from '../ToolbarButton';

const t = initTranslations('editor');

interface Props {
  alignment: 'left' | 'right' | 'center';
}

const TextAlignToolbarButton = ({ alignment }: Props) => {
  const { editor } = useEditorContext();
  return (
    <ToolbarButton
      active={editor.isActive({ textAlign: alignment })}
      ariaLabel={t(`aria_label_align_text_${alignment}_button`)}
      iconName={`align-${alignment}`}
      id={`text-align-${alignment}-button`}
      onClick={() => editor.commands.setTextAlign(alignment)}
      tooltipId={`text-align-${alignment}-tooltip`}
      tooltipText={t('align_text', { alignment })}
      weight='regular'
    />
  );
};

export const TextAlignCenterToolbarButton = () => {
  return <TextAlignToolbarButton alignment='center'></TextAlignToolbarButton>;
};

export const TextAlignRightToolbarButton = () => {
  return <TextAlignToolbarButton alignment='right'></TextAlignToolbarButton>;
};

export const TextAlignLeftToolbarButton = () => {
  return <TextAlignToolbarButton alignment='left'></TextAlignToolbarButton>;
};
