import React from 'react';

import { useEditorContext } from '../../../../../../../contexts/EditorContext';
import initTranslations from '../../../../../../../lib/initTranslations';
import ToolbarButton from '../ToolbarButton';

const t = initTranslations('editor');

const UndoToolbarButton = () => {
  const { editor } = useEditorContext();

  //TODO: create undo / redo buttons to match the design
  return (
    <ToolbarButton
      active={editor.isActive('undo')}
      ariaLabel={t('aria_label_undo_button')}
      className={editor.isActive('undo') ? 'is-active' : ''}
      iconName='rotate-left'
      id='undo-button'
      onClick={() => editor.chain().focus().undo().run()}
      tooltipId='undo-tooltip'
      tooltipText={t('undo')}
    />
  );
};

export default UndoToolbarButton;
