import React from 'react';

import { useEditorContext } from '../../../../../../../contexts/EditorContext';
import initTranslations from '../../../../../../../lib/initTranslations';
import ToolbarButton from '../ToolbarButton';

const t = initTranslations('editor');

const ClearFormattingToolbarButton = () => {
  const { editor } = useEditorContext();
  const textStyles = ['lineHeight', 'textAlign', 'indent'];

  return (
    <ToolbarButton
      active={false}
      ariaLabel={t('aria_label_unset_button')}
      iconName='remove-format'
      id='clear-formatting-button'
      onClick={() =>
        editor
          .chain()
          .focus()
          .extendMarkRange('link')
          .unsetAllMarks()
          .resetAttributes('paragraph', textStyles)
          .resetAttributes('heading', textStyles)
          .run()
      }
      tooltipId='clear-formatting-tooltip'
      tooltipText={t('clear_formatting')}
    />
  );
};

export default ClearFormattingToolbarButton;
