import React, { useCallback } from 'react';
import styled from 'styled-components';

import { useEditorContext } from '../../../../../../../contexts/EditorContext';
import initTranslations from '../../../../../../../lib/initTranslations';
import Icon from '../../../../../../design_system/display/icons/Icon';
import { fontMd1 } from '../../../../../../styled/TypeSystem';
import DropdownWithPoppableMenu from '../../../../../shared/DropdownWithPoppableMenu';
import { LINE_HEIGHTS } from '../../../../shared/constants/editor';

const OptionWrapper = styled.div`
  min-width: 8rem;
  ${fontMd1};
`;

const t = initTranslations('editor');

const LineHeightDropdownButton = () => {
  const { editor } = useEditorContext();

  const getLineHeight: () => string | null = () => {
    for (const nodeType of ['paragraph', 'heading']) {
      if (editor.isActive(nodeType)) {
        return editor.getAttributes(nodeType).lineHeight;
      }
    }

    return null;
  };

  const activeLineHeight = getLineHeight();
  const activeIndex = LINE_HEIGHTS.findIndex(({ lineHeight }) => activeLineHeight === lineHeight);

  const setSelectedOption = useCallback(
    (index: number) => {
      const selectedLineHeight = LINE_HEIGHTS[index];
      editor.chain().focus().setLineHeight(selectedLineHeight.lineHeight).run();
    },
    [editor]
  );

  return (
    <DropdownWithPoppableMenu
      activeOptionIndex={activeIndex}
      id='line-height-dropdown'
      menuId='line-height-menu'
      options={LINE_HEIGHTS.map((item, index) => (
        <OptionWrapper key={`option-${index}`}>{item.label}</OptionWrapper>
      ))}
      selectedOption={<Icon name='line-height' />}
      setSelectedOption={setSelectedOption}
      style='toolbar'
      tooltipId='line-height-tooltip'
      tooltipPlace='bottom'
      tooltipText={t('line_spacing')}
    />
  );
};

export default LineHeightDropdownButton;
