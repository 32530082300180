import { useCallback } from 'react';

import { useEditorContext } from '../../../../../contexts/EditorContext';

type ListType = 'taskList' | 'orderedList' | 'bulletList';

export const useToggleList = () => {
  const { editor } = useEditorContext();

  const isActiveList = useCallback(
    (listType: ListType) => {
      return editor.isActive(listType);
    },
    [editor]
  );

  const toggleList = useCallback(
    (listType: ListType, callback: () => void): (() => void) => {
      return () => {
        if (listType === 'taskList') {
          // This logic is implemented to support replacement of BulletList/OrderedList with TaskList if present
          if (isActiveList('taskList')) {
            editor.chain().focus().toggleTaskList().run();
          } else {
            editor.chain().focus().clearNodes().toggleTaskList().run();
          }
        } else {
          editor.chain().focus().toggleList(listType, 'listItem').run();
        }
        callback();
      };
    },
    [editor, isActiveList]
  );

  return { toggleList, isActiveList };
};
