import { IconName } from '@fortawesome/fontawesome-svg-core';
import React, { MouseEventHandler } from 'react';
import styled, { css } from 'styled-components';

import { Emoji } from '../../../../types/Emoji';
import { fontMd3, fontSm4, fontSm5 } from '../../../styled/TypeSystem';
import Avatar from '../../display/avatar';
import Link from '../../Link';
import { TruncatedText } from '../../Triage/TruncatedText';
import { AvatarSize } from '../avatar/AvatarTypes';
import Badge from '../badge';
import { BadgeType } from '../badge/BadgeTypes';

const labelFont = css<{ avatarSize: DetailedAvatarSize }>`
  ${({ avatarSize, theme: { constants } }) => {
    switch (avatarSize) {
      case 'md':
        return css`
          font-weight: ${constants.fontMedium};
          ${fontSm5};
        `;
      case 'lg':
        return css`
          font-weight: ${constants.fontBold};
          ${fontMd3};
        `;
    }
  }}
`;

const subLabelFont = css<{ avatarSize: DetailedAvatarSize }>`
  ${({ avatarSize, theme: { constants } }) => {
    switch (avatarSize) {
      case 'md':
        return css`
          font-weight: ${constants.fontRegular};
          ${fontSm4};
        `;
      case 'lg':
        return css`
          font-weight: ${constants.fontRegular};
          ${fontSm5};
        `;
    }
  }}
`;

const DetailedAvatarWrapper = styled.div<{ onClick?: MouseEventHandler | undefined }>`
  display: flex;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
  overflow: hidden;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
`;

const AvatarWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Label = styled.div<{ avatarSize: DetailedAvatarSize; extendedLabel?: string }>`
  color: ${({ theme: { vars } }) => vars.textDefault};
  margin-bottom: 0;
  ${labelFont};
  ${TruncatedText({})};
`;

const LinkedLabel = styled(Link)<{ avatarSize: DetailedAvatarSize }>`
  color: ${({ theme: { vars } }) => vars.textDefault};
  text-decoration: none;
  ${labelFont};
  ${TruncatedText({})};
`;

const LabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${({ theme: { constants } }) => constants.spacerSm2};
  ${TruncatedText({})};
`;

const FirstLineLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme: { constants } }) => constants.spacerSm2};
`;

const SubLabel = styled.div<{ avatarSize: DetailedAvatarSize }>`
  color: ${({ theme: { vars } }) => vars.textPlaceholder};
  ${subLabelFont};
  ${TruncatedText({})};
`;

const LinkedSubLabel = styled(Link)<{ avatarSize: DetailedAvatarSize }>`
  color: ${({ theme: { vars } }) => vars.textSubdued};
  text-align: left;
  text-decoration: none;
  :hover {
    text-decoration: underline;
  }
  ${subLabelFont};
`;

const ExtendedLabel = styled.div<{ avatarSize: DetailedAvatarSize }>`
  color: ${({ theme: { vars } }) => vars.textSubdued};
  ${labelFont};
`;

const ExtendedBadge = styled.div`
  margin-left: ${({ theme: { constants } }) => constants.spacerSm2};
  ${fontSm5};
`;

const getAvatarSize = (size: 'md' | 'lg'): AvatarSize => {
  switch (size) {
    case 'md':
      return 'md';
    case 'lg':
      return 'xl';
  }
};

export type SubLabelProps =
  | {
      subLabel: string | undefined;
      subLabelLink?: string;
      subLabelOnClick?: never;
    }
  | {
      subLabel: string | undefined;
      subLabelLink?: never;
      subLabelOnClick?: () => void;
    }
  | {
      subLabel?: never;
      subLabelLink?: never;
      subLabelOnClick?: never;
    };

export type DependentAvatarProps =
  | {
      avatarImage: string | undefined;
      avatarIcon?: never;
      avatarEmoji?: never;
    }
  | {
      avatarImage?: never;
      avatarIcon: IconName;
      avatarEmoji?: Emoji;
    }
  | {
      avatarImage?: never;
      avatarIcon?: never;
      avatarEmoji?: never;
    };

export type BadgeProps =
  | {
      badge?: BadgeType;
      badgeText?: string;
    }
  | {
      badge?: never;
      badgeText?: never;
    };

type DetailedAvatarSize = 'md' | 'lg';

export type DetailedAvatarProps = {
  label: string;
  id?: string;
  labelLink?: string;
  extendedLabel?: string;
  size?: DetailedAvatarSize;
  onAvatarClick?: () => void;
  onClick?: () => void;
} & SubLabelProps &
  DependentAvatarProps &
  BadgeProps;

const DetailedAvatar = ({
  id,
  label,
  labelLink,
  extendedLabel,
  subLabel,
  subLabelLink,
  avatarImage,
  avatarEmoji,
  avatarIcon,
  badge,
  badgeText,
  onAvatarClick,
  onClick,
  size = 'md',
  subLabelOnClick,
}: DetailedAvatarProps) => {
  return (
    <DetailedAvatarWrapper id={id} onClick={onClick}>
      <AvatarWrapper id='avatar-wrapper'>
        <Avatar
          emoji={avatarEmoji}
          icon={avatarIcon}
          image={avatarImage}
          name={label}
          onClick={onAvatarClick}
          shape='circle'
          size={getAvatarSize(size)}
        />
      </AvatarWrapper>
      <LabelWrapper className='label-wrapper'>
        <FirstLineLabelWrapper className='notranslate'>
          {labelLink ? (
            <LinkedLabel avatarSize={size} href={labelLink} text={label} />
          ) : (
            <Label avatarSize={size} extendedLabel={extendedLabel}>
              {label}
            </Label>
          )}
          {extendedLabel && <ExtendedLabel avatarSize={size}>({extendedLabel})</ExtendedLabel>}
          {badge && (
            <ExtendedBadge>
              <Badge fontWeight='regular' text={badgeText} type={badge} />
            </ExtendedBadge>
          )}
        </FirstLineLabelWrapper>
        {subLabel &&
          (subLabelLink ? (
            <LinkedSubLabel avatarSize={size} color='subdued' href={subLabelLink} text={subLabel} />
          ) : subLabelOnClick ? (
            <LinkedSubLabel
              avatarSize={size}
              behavesAs='button'
              onClick={() => {
                subLabelOnClick?.();
              }}
              text={subLabel}
            />
          ) : (
            <SubLabel avatarSize={size}>{subLabel}</SubLabel>
          ))}
      </LabelWrapper>
    </DetailedAvatarWrapper>
  );
};

export default DetailedAvatar;
