import React, { ChangeEvent, KeyboardEvent, forwardRef } from 'react';
import styled, { css } from 'styled-components';

import initTranslations from '../../../../../lib/initTranslations';
import { IconPosition } from '../../../buttons/DefaultButton/ButtonTypes';
import IconButton from '../../../buttons/IconButton';
import Icon from '../../../display/icons/Icon';
import InputField, { Input } from '../../InputField';
import Loader from '../../Loader';

const StyledInputField = styled(InputField)<{ iconPosition: IconPosition }>`
  border: none;
  border-radius: 0;
  ${({ theme, iconPosition }) =>
    iconPosition === 'right' &&
    css`
      margin-left: ${theme.constants.spacerMd1};
    `}

  &:placeholder-shown {
    text-overflow: ellipsis;
  }
`;

const StyledInputGroup = styled.div<{ showBorder: boolean; hasText: boolean; disabled: boolean }>`
  background-color: ${({ theme: { vars }, disabled }) =>
    disabled ? vars.foundationBase1 : vars.foundationSurface1};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'auto')};
  align-items: center;
  display: flex;
  height: ${({ theme: { constants } }) => constants.heightMd};
  color: ${({ theme: { vars }, hasText }) => (hasText ? vars.textDefault : vars.textPlaceholder)};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusLg};
  overflow: hidden;

  ${Input} {
    padding: 0 ${({ theme: { constants } }) => constants.spacerSm1} 0
      ${({ theme: { constants } }) => constants.spacerSm3};
  }

  ${({ showBorder }) =>
    showBorder &&
    css`
      border: ${({ theme: { constants } }) => constants.borderWidthSm} solid
        ${({ theme: { vars } }) => vars.borderDefault};
      border-radius: ${({ theme: { constants } }) => constants.borderRadiusLg};

      &:focus-within,
      &:focus-within > span {
        outline: none;
        color: ${({ theme: { vars } }) => vars.textDefault};
        border-color: ${({ theme: { vars } }) => vars.accentPrimaryDefault};
        caret-color: ${({ theme: { vars } }) => vars.textDefault};
      }
    `};
`;

const SearchIcon = styled(Icon)<{ iconPosition: IconPosition }>`
  ${({ theme, iconPosition }) =>
    iconPosition === 'right'
      ? `margin-right: ${theme.constants.spacerMd1};`
      : `margin-left: ${theme.constants.spacerMd1};`}
`;

const StyledSpan = styled.span<{ showBorder: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
  height: 100%;
  color: ${({ theme: { vars } }) => vars.textDefault};
`;

const LoaderWrapper = styled.div`
  margin-right: ${({ theme: { constants } }) => constants.spacerMd1};
`;

const ariaTranslate = initTranslations('aria');

export interface Props {
  searchValue: string;
  setSearchValue: (value: string) => void;
  placeholder?: string;
  dataLoading?: boolean;
  showBorder?: boolean;
  autoFocus?: boolean;
  className?: string;
  iconPosition?: IconPosition;
  setIsFocused?: (value: boolean) => void;
  onEnterSubmit?: () => void;
  disabled?: boolean;
}

type SearchState = 'empty' | 'loading' | 'filled';

const ClearAndLoad = (props: Props) => {
  const { dataLoading, searchValue, setSearchValue, showBorder = true } = props;

  let state: SearchState = 'empty';
  if (searchValue.length) {
    state = dataLoading ? 'loading' : 'filled';
  }

  return (
    <StyledSpan showBorder={showBorder}>
      {state === 'loading' && (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      )}
      {state === 'filled' && (
        <IconButton
          ariaLabel={ariaTranslate('aria_label_clear_search')}
          buttonSize='sm'
          className='search-btn-test-hook'
          name='circle-x'
          onClick={() => setSearchValue('')}
          weight='regular'
        />
      )}
    </StyledSpan>
  );
};

const SearchField = forwardRef(function SearchField(
  props: Props,
  ref: React.RefObject<HTMLInputElement>
) {
  const {
    searchValue,
    setSearchValue,
    placeholder,
    dataLoading = false,
    showBorder = true,
    autoFocus = false,
    className = '',
    iconPosition = 'left',
    setIsFocused,
    onEnterSubmit,
    disabled = false,
  } = props;

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target) {
      setSearchValue(event.target.value);
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      onEnterSubmit && onEnterSubmit();
    }
  };

  return (
    <StyledInputGroup
      className={className}
      disabled={disabled}
      hasText={searchValue.length > 0}
      showBorder={showBorder}
    >
      {iconPosition === 'left' && (
        <SearchIcon iconPosition={iconPosition} name='search' weight='regular' />
      )}
      <StyledInputField
        autoFocus={autoFocus}
        disabled={disabled}
        forwardRef={ref}
        iconPosition={iconPosition}
        id='search-field'
        onBlur={() => setIsFocused && setIsFocused(false)}
        onChange={handleChange}
        onFocus={() => setIsFocused && setIsFocused(true)}
        onKeyDown={handleKeyDown}
        placeholder={placeholder}
        showBorder={false}
        value={searchValue}
      />
      <ClearAndLoad
        dataLoading={dataLoading}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        showBorder={showBorder}
      />
      {iconPosition === 'right' && (
        <SearchIcon iconPosition={iconPosition} name='search' weight='regular' />
      )}
    </StyledInputGroup>
  );
});

export default SearchField;
