export const FILL_COLORS = [
  'neutral[15]',
  'purple[10]',
  'blue[10]',
  'green[05]',
  'yellow[10]',
  'orange[10]',
  'magenta[10]',
  'red[10]',
  'neutral[50]',
  'purple[50]',
  'blue[50]',
  'green[40]',
  'yellow[50]',
  'orange[50]',
  'magenta[50]',
  'red[50]',
  'neutral[90]',
  'purple[80]',
  'blue[80]',
  'green[80]',
  'yellow[80]',
  'orange[80]',
  'magenta[80]',
  'red[80]',
];

export const TEXT_COLORS = [
  'neutral[00]',
  'purple[20]',
  'blue[20]',
  'green[20]',
  'yellow[30]',
  'magenta[30]',
  'red[20]',
  'neutral[50]',
  'purple[30]',
  'blue[30]',
  'green[30]',
  'yellow[40]',
  'magenta[40]',
  'red[40]',
  'neutral[60]',
  'purple[50]',
  'blue[50]',
  'green[50]',
  'yellow[50]',
  'magenta[50]',
  'red[50]',
  'neutral[80]',
  'purple[70]',
  'blue[70]',
  'green[90]',
  'yellow[90]',
  'magenta[90]',
  'red[90]',
];

export const HIGHLIGHT_COLORS = [
  'neutral[05]',
  'purple[00]',
  'blue[00]',
  'green[00]',
  'yellow[00]',
  'magenta[00]',
  'orange[00]',
  'red[00]',
  'neutral[15]',
  'purple[10]',
  'blue[10]',
  'green[10]',
  'yellow[10]',
  'magenta[10]',
  'orange[10]',
  'red[10]',
];
