import { ContentStyles } from '../types/ContentStyle';

const PublicContentStyles: ContentStyles = {
  a: {
    color: '#38D4AE',
    fontSize: '16px',
    fontStyle: 'normal',
    textAlign: 'left',
    fontFamily: 'Poppins',
    fontWeight: 'normal',
    textDecoration: 'none',
  },
  p: {
    color: '#494D55',
    fontSize: '16px',
    fontStyle: 'normal',
    textAlign: 'left',
    fontFamily: 'Poppins',
    fontWeight: 'normal',
    textDecoration: 'none',
  },
  h1: {
    color: '#6A28EA',
    fontSize: '30px',
    fontStyle: 'normal',
    textAlign: 'left',
    fontFamily: 'Poppins',
    fontWeight: '600',
    textDecoration: 'none',
  },
  h2: {
    color: '#6A28EA',
    fontSize: '24px',
    fontStyle: 'normal',
    textAlign: 'left',
    fontFamily: 'Poppins',
    fontWeight: '600',
    textDecoration: 'none',
  },
  h3: {
    color: '#020044',
    fontSize: '18px',
    fontStyle: 'normal',
    textAlign: 'left',
    fontFamily: 'Poppins',
    fontWeight: '600',
    textDecoration: 'none',
  },
  h4: {
    color: '#252430',
    fontSize: '18px',
    fontStyle: 'normal',
    textAlign: 'left',
    fontFamily: 'Poppins',
    fontWeight: '600',
    textDecoration: 'none',
  },
  hr: { borderColor: '#38D4AE', borderWidth: '4px' },
};

export default PublicContentStyles;
