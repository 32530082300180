import { Editor } from '@tiptap/react';
import { useCallback } from 'react';

import { secondaryHighlightPlugin } from '../SecondaryHighlight';

type Props = {
  editor: Editor;
};

const useSecondaryHighlight = ({ editor }: Props) => {
  return useCallback(
    (addDecoration: boolean) => {
      const { state, view } = editor;
      const { from, to } = state.selection;

      view.dispatch(view.state.tr.setMeta(secondaryHighlightPlugin, { addDecoration, from, to }));
    },
    [editor]
  );
};

export default useSecondaryHighlight;
