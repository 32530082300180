import React, { ReactElement, ReactNode, useContext } from 'react';

import useCurrentAccount from '../hooks/useCurrentAccount';
import AccountTerminology from '../types/AccountTerminology';

type AccountTerminologyProps = {
  children: ReactNode;
};

export const AccountTerminologyContext = React.createContext<AccountTerminology>(
  {} as AccountTerminology
);
export const useAccountTerminology = () => useContext(AccountTerminologyContext);

const AccountTerminologyProvider = ({ children }: AccountTerminologyProps): ReactElement => {
  const { accountTerminology } = useCurrentAccount();
  return (
    <AccountTerminologyContext.Provider value={{ ...accountTerminology }}>
      {children}
    </AccountTerminologyContext.Provider>
  );
};

export { AccountTerminologyProvider };
