import React, { useState } from 'react';

import useWindowResize from '../../../../hooks/useWindowResize';
import Icon from '../../../design_system/display/icons/Icon';
import Tooltip from '../../../design_system/display/Tooltip/Tooltip';
import { ConditionalTooltipProps } from '../../../design_system/display/Tooltip/types';
import Poppable, { Placement, Strategy } from '../../../Poppable';
import { Direction } from '../../../styled/FlexContainer';
import { getChevronIconName } from '../helpers';
import OutsideClickHandler from '../OutsideClickHandler';
import PoppableDropdownMenu from './PoppableDropdownMenu';
import { MenuItemType, MessageProps, Options } from './PoppableDropdownMenu/PoppableDropdownMenu';
import {
  DelayTypeStyledDropdown,
  DelegationBoardStyledDropdown,
  ESignaturePaywallBannerStyledDropdown,
  GeneralAccessStyledDropdown,
  IconWrapper,
  SectorStyledDropdown,
  StyledDropdown,
  SubjectModeDropdown,
  SupershareStyledDropdown,
  SurveyStyledDropdown,
  ToolbarStyledDropdown,
} from './styles';

const dropdownStyles = {
  default: StyledDropdown,
  toolbar: ToolbarStyledDropdown,
  supershare: SupershareStyledDropdown,
  generalaccess: GeneralAccessStyledDropdown,
  survey: SurveyStyledDropdown,
  sector: SectorStyledDropdown,
  delegationBoard: DelegationBoardStyledDropdown,
  subjectMode: SubjectModeDropdown,
  delayType: DelayTypeStyledDropdown,
  eSignaturePaywallBanner: ESignaturePaywallBannerStyledDropdown,
};
export type DropdownWithPoppableMenuProps = {
  id: string;
  menuId: string;
  options: Options;
  disabledOptionIndexes?: number[];
  activeOptionIndex?: number | undefined;
  menuItemDirection?: Extract<Direction, 'row' | 'column'>;
  menuItemType?: MenuItemType;
  menuPlacement?: Placement;
  selectedOption: React.ReactNode;
  setSelectedOption: (indexOfSelectedOption: number) => void;
  style?:
    | 'default'
    | 'toolbar'
    | 'supershare'
    | 'survey'
    | 'generalaccess'
    | 'sector'
    | 'delegationBoard'
    | 'subjectMode'
    | 'delayType'
    | 'eSignaturePaywallBanner';
  useMenuWidth?: boolean;
  lastItemDivider?: boolean;
  dataTestId?: string;
  onDropdownToggle?: (isOpen: boolean) => void;
  lockDropdown?: boolean;
  strategy?: Strategy;
  menuStyling?: React.CSSProperties;
  menuFooterElement?: React.ReactNode;
} & MessageProps &
  ConditionalTooltipProps;

const DropdownWithPoppableMenu = ({
  id,
  menuId,
  options,
  activeOptionIndex,
  menuItemDirection = 'column',
  menuItemType = 'default',
  menuPlacement = 'bottom-start',
  selectedOption,
  setSelectedOption,
  style = 'default',
  tooltipBreakpoint,
  tooltipId,
  tooltipPlace,
  tooltipText,
  useMenuWidth,
  lastItemDivider = false,
  messageSubtitle,
  messageTitle,
  dataTestId,
  disabledOptionIndexes,
  onDropdownToggle,
  delayTooltipShow,
  lockDropdown = false,
  strategy,
  menuStyling = {},
  menuFooterElement,
}: DropdownWithPoppableMenuProps) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const { width } = useWindowResize();
  const isExistRequiredTooltipData = tooltipId && tooltipText;
  const isMatchTooltipBreakpoint =
    !tooltipBreakpoint || (tooltipBreakpoint && tooltipBreakpoint > width); // If tooltipBreakpoint is not defined, it will always show tooltip
  const showTooltip = isExistRequiredTooltipData && isMatchTooltipBreakpoint && !dropdownOpen;
  const toggleDropdown = () => {
    !lockDropdown && setDropdownOpen((prevState) => !prevState);
    if (onDropdownToggle) {
      onDropdownToggle(!dropdownOpen);
    }
  };

  const DropdownComponent = dropdownStyles[style];

  const messageProps = (): MessageProps => {
    if (messageTitle && messageSubtitle) {
      return { messageTitle, messageSubtitle };
    }

    if (messageTitle) {
      return { messageTitle };
    }

    return {};
  };

  const iconName = getChevronIconName({
    isActive: dropdownOpen,
    initialDirection: menuPlacement === 'right' ? 'right' : 'up',
  });

  return (
    <OutsideClickHandler onOutsideClick={() => setDropdownOpen(false)}>
      <Poppable
        isOpen={dropdownOpen}
        item={
          <PoppableDropdownMenu
            activeOptionIndex={activeOptionIndex}
            disabledOptionIndexes={disabledOptionIndexes}
            dropdownOpen={dropdownOpen}
            footerElement={menuFooterElement}
            lastItemDivider={lastItemDivider}
            menuId={menuId}
            menuItemDirection={menuItemDirection}
            menuItemType={menuItemType}
            options={options}
            setSelectedOption={setSelectedOption}
            toggleDropdown={toggleDropdown}
            {...messageProps()}
          />
        }
        menuStyling={menuStyling}
        onClick={toggleDropdown}
        placement={menuPlacement}
        strategy={strategy}
        trigger={
          <>
            {showTooltip && (
              <Tooltip
                delayShow={delayTooltipShow}
                id={tooltipId}
                place={tooltipPlace}
                text={tooltipText}
              />
            )}
            <div data-for={tooltipId} data-tip>
              <DropdownComponent
                aria-expanded={dropdownOpen}
                aria-haspopup='true'
                aria-owns={menuId}
                data-testid={dataTestId}
                id={id}
                isOpen={dropdownOpen}
              >
                {selectedOption}

                {!lockDropdown && (
                  <IconWrapper>
                    <Icon name={iconName} size='xs' weight='solid' />
                  </IconWrapper>
                )}
              </DropdownComponent>
            </div>
          </>
        }
        useMenuWidth={useMenuWidth}
      />
    </OutsideClickHandler>
  );
};

export default DropdownWithPoppableMenu;
