import { inRange } from 'lodash';
import { useTheme } from 'styled-components';

const useProgressColor = ({ percent }: { percent: number }) => {
  const { vars } = useTheme();

  if (percent === 0) {
    return vars.foundationBase2;
  } else if (inRange(percent, 1, 29)) {
    return vars.stateCompletionLow;
  } else if (inRange(percent, 30, 59)) {
    return vars.stateCompletionMedium;
  } else if (inRange(percent, 60, 99)) {
    return vars.stateCompletionHigh;
  } else {
    return vars.stateCompletionFull;
  }
};

export default useProgressColor;
