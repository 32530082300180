import { useCallback } from 'react';

import { useEditorContext } from '../../../../../../../../contexts/EditorContext';

export const useHandleIndentClick = () => {
  const { editor } = useEditorContext();

  return useCallback(
    (indentationOperation: 'indent' | 'outdent') => {
      const { selection } = editor.state;
      const listItemTypeName = 'listItem';
      const node = selection.$head.node();
      const parentNode = selection.$head.node(-1);

      if (node.type.name === listItemTypeName || parentNode.type.name === listItemTypeName) {
        indentationOperation === 'indent'
          ? editor.chain().focus().sinkListItem('listItem').run()
          : editor.chain().focus().liftListItem('listItem').run();
      } else {
        indentationOperation === 'indent'
          ? editor.chain().focus().indent().run()
          : editor.chain().focus().outdent().run();
      }
    },
    [editor]
  );
};
