import { InsertRowBelowOutlined } from '@ant-design/icons';
import React from 'react';

import { useEditorContext } from '../../../../../../../contexts/EditorContext';
import initTranslations from '../../../../../../../lib/initTranslations';
import CustomToolBarButton from '../CustomToolbarButton/CustomToolbarButton';

const t = initTranslations('editor');

const AddRowAfterButton = () => {
  const { editor } = useEditorContext();

  return (
    <CustomToolBarButton
      ariaLabel={t('aria_label_row_after_button')}
      customIcon={<InsertRowBelowOutlined rev={undefined} />}
      id='add-row-after-button'
      onClick={() => editor.chain().focus().addRowAfter().run()}
      tooltipId='add-row-after-button-tooltip'
      tooltipText={t('add_row_after')}
    />
  );
};

export default AddRowAfterButton;
