import React from 'react';

import { useEditorContext } from '../../../../../../../contexts/EditorContext';
import initTranslations from '../../../../../../../lib/initTranslations';
import ToolbarButton from '../ToolbarButton';

const t = initTranslations('editor');

const RedoToolbarButton = () => {
  const { editor } = useEditorContext();

  return (
    <ToolbarButton
      active={editor.isActive('redo')}
      ariaLabel={t('aria_label_redo_button')}
      className={editor.isActive('redo') ? 'is-active' : ''}
      iconName='rotate-right'
      id='redo-button'
      onClick={() => editor.chain().focus().redo().run()}
      tooltipId='redo-tooltip'
      tooltipText={t('redo')}
    />
  );
};

export default RedoToolbarButton;
