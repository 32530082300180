import { Formik, FormikProps } from 'formik';
import React from 'react';
import styled, { css } from 'styled-components';

import { RegisteredMenuId } from '../../../lib/idRegistry';
import initTranslations from '../../../lib/initTranslations';
import { isColorWhite } from '../../application/editor/shared/helpers';
import { validateHexCodeSchema } from '../../application/editor/shared/validators';
import useActiveMenuHandler from '../../application/publicApplication/utils/useActiveMenuHandler';
import DefaultButton from '../buttons/DefaultButton';
import InputField, { Props as InputProps } from '../Triage/InputField';

const InputAndButtonContainer = styled.div<{
  color: string | undefined;
}>`
  display: flex;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
  .fa-circle {
    color: ${({ color, theme: { vars } }) => (!!color ? color : vars.textPlaceholder)};
    ${({ color, theme: { constants, vars } }) =>
      isColorWhite(color) &&
      css`
        border: ${constants.borderWidthLg} solid ${vars.borderDefault};
        border-radius: ${({ theme: { constants } }) => constants.borderRadiusCircle};
      `}
  }
`;

// DS override: Ensures button text does not wrap
const SelectionButton = styled(DefaultButton)`
  overflow: hidden;
  white-space: nowrap;
`;

const t = initTranslations('color_picker');

type Props = {
  selectionButtonText?: string;
  flyoutId: RegisteredMenuId;
  color: string | undefined;
  onChange: (color: string) => void;
};

const ColorPickerInput = ({
  color,
  onChange,
  selectionButtonText = t('use_color'),
  flyoutId,
}: Props) => {
  const { closeMenu } = useActiveMenuHandler({ menuId: flyoutId });

  const inputIconProps = (color: string | undefined) => {
    return {
      iconName: color ? 'circle' : 'empty-set',
      iconSize: '2xs',
      iconWeight: color ? 'solid' : 'regular',
    } as InputProps;
  };

  const handleSubmit = (values: { color: string }) => {
    if (!values.color) return;

    onChange(values.color);
    closeMenu();
  };

  return (
    <Formik
      initialValues={{ color }}
      onSubmit={handleSubmit}
      validationSchema={validateHexCodeSchema}
    >
      {({ errors, handleSubmit, handleChange, values }: FormikProps<{ color: string }>) => (
        <InputAndButtonContainer color={!errors.color ? values.color : undefined}>
          <InputField
            {...inputIconProps(!errors.color ? values.color : undefined)}
            errorText={errors.color}
            name='color'
            onChange={handleChange}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                handleSubmit();
              }
            }}
            placeholder={t('placeholder')}
            value={values.color}
          />
          <SelectionButton
            buttonType='secondary'
            disabled={!!errors.color}
            id='color-picker-selection-button'
            onClick={() => handleSubmit()}
            text={selectionButtonText}
          />
        </InputAndButtonContainer>
      )}
    </Formik>
  );
};

export default ColorPickerInput;
