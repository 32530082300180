import React from 'react';

import { useEditorContext } from '../../../../../../../contexts/EditorContext';
import initTranslations from '../../../../../../../lib/initTranslations';
import ToolbarButton from '../ToolbarButton';

const t = initTranslations('editor');

const UnorderedListToolbarButton = () => {
  const { editor } = useEditorContext();

  return (
    <ToolbarButton
      active={editor.isActive('bulletList')}
      ariaLabel={t('aria_label_unordered_list_button')}
      className={editor.isActive('bulletList') ? 'is-active' : ''}
      iconName='list'
      id='unorderedList-button'
      onClick={() => editor.chain().focus().toggleBulletList().run()}
      tooltipId='unorderedList-tooltip'
      tooltipText={t('bulleted_list')}
    />
  );
};

export default UnorderedListToolbarButton;
