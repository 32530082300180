import React, { useCallback, useEffect, useRef } from 'react';
import { useTheme } from 'styled-components';

import { useEditorContext } from '../../../../../../../contexts/EditorContext';
import initTranslations from '../../../../../../../lib/initTranslations';
import DefaultButton from '../../../../../../design_system/buttons/DefaultButton';
import ColorPickerTrigger from '../../../../../../design_system/buttons/DeprecatedColorPicker/ColorPickerTrigger';
import ColorPicker from '../../../../../../design_system/ColorPicker/ColorPicker';
import ColorPickerInput from '../../../../../../design_system/ColorPicker/ColorPickerInput';
import { FILL_COLORS } from '../../../../../../design_system/ColorPicker/constants';
import FillSwatch from '../../../../../../design_system/ColorPicker/Swatches/FillSwatch';
import { SwatchesWrapper } from '../../../../../../design_system/ColorPicker/Swatches/styles';
import { paletteColorDecoder } from '../../../../../../design_system/helpers';
import useActiveMenuHandler from '../../../../../publicApplication/utils/useActiveMenuHandler';
import { StyledTooltip } from '../../../../shared/styles';
import { useEditorColorHandler } from '../../../../shared/useEditorColorHandler';
import { isTableCellSelected } from '../../../../tiptap_extensions/table/utils';

const t = initTranslations('editor');

const DEFAULT_CELL_COLOR = '#FFFFFF';
const MENU_ID = 'editor-cell-color-picker';

const CellBackgroundColorButton = () => {
  const { palettes } = useTheme();
  const { editor } = useEditorContext();

  const cellColor = editor.getAttributes('tableCell').backgroundColor || DEFAULT_CELL_COLOR;
  const decodedCellColor = paletteColorDecoder(palettes, cellColor);

  const updateCellBackgroundColor = useCallback(
    (color: string) => {
      const cellAttributes = editor.getAttributes('tableCell');
      const currentStyles = cellAttributes.style || '';

      // Removes any instance of background-color from the style string
      const updatedStyle = currentStyles.replace(/background-color:[^;]+;?/g, '').trim();

      // Resets the cell styles with no background-color styles
      if (updatedStyle) {
        editor.chain().focus().setCellAttribute('style', updatedStyle).run();
      }

      // Sets the cell background color using the backgroundColor attribute
      editor.chain().focus().setCellAttribute('backgroundColor', color).run();
    },
    [editor]
  );

  const { handleColorSet } = useEditorColorHandler('textStyle', (color) => {
    updateCellBackgroundColor(color);
  });

  const { closeMenu } = useActiveMenuHandler({ menuId: MENU_ID });

  const prevSelectionRef = useRef<number | null>(null);

  useEffect(() => {
    const currentSelection = editor.state.selection.$anchor.pos;

    if (isTableCellSelected(editor.state.selection)) {
      if (prevSelectionRef.current !== null && prevSelectionRef.current !== currentSelection) {
        closeMenu();
      }
    }
    prevSelectionRef.current = currentSelection;
  }, [closeMenu, editor.state.selection]);

  return (
    <>
      <StyledTooltip id='cell-background-color-tooltip' text={t('background_color')} />
      <div data-for='cell-background-color-tooltip' data-tip='true'>
        <ColorPicker
          flyoutId={MENU_ID}
          strategy='absolute'
          trigger={
            <ColorPickerTrigger
              ariaLabel={t('aria_label_cell_background_color_button')}
              buttonId='editor-table-color-button'
              color={decodedCellColor}
              type='cell-bg-color'
            />
          }
        >
          <SwatchesWrapper rowCount={8}>
            {FILL_COLORS.map((color) => {
              return (
                <FillSwatch
                  activeColor={decodedCellColor}
                  color={paletteColorDecoder(palettes, color)}
                  key={color}
                  onClick={() => {
                    handleColorSet(color);
                    closeMenu();
                  }}
                />
              );
            })}
          </SwatchesWrapper>
          <ColorPickerInput color={decodedCellColor} flyoutId={MENU_ID} onChange={handleColorSet} />
          <DefaultButton
            buttonType='tertiary'
            fullWidth
            iconName='droplet-slash'
            id='editor-cell-color-removal-button'
            onClick={() => {
              updateCellBackgroundColor(DEFAULT_CELL_COLOR);
              closeMenu();
            }}
            text={t('remove_fill')}
          />
        </ColorPicker>
      </div>
    </>
  );
};

export default CellBackgroundColorButton;
