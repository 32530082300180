import { IconName } from '@fortawesome/fontawesome-svg-core';
import React, { useContext } from 'react';
import { PolarAngleAxis, RadialBar, RadialBarChart } from 'recharts';
import styled, { DefaultTheme, css, useTheme } from 'styled-components';

import { PaywallContext } from '../../../../contexts/PaywallContext';
import useProgressColor from '../../../../hooks/useProgressColor';
import { getFontStyle } from '../../../../lib/fontStyle';
import { userInitials } from '../../../../lib/userNaming';
import { Emoji } from '../../../../types/Emoji';
import FlexContainer from '../../../styled/FlexContainer';
import Icon from '../icons/Icon/Icon';
import { IconSize } from '../icons/Icon/IconTypes';
import { AvatarShape, AvatarSize } from './AvatarTypes';
import { getAvatarCircleStyles } from './circleStyles';
import { getAvatarSquareStyles } from './squareStyles';

export interface Props {
  className?: string;
  completionPercent?: number;
  emoji?: Emoji;
  image?: string;
  icon?: IconName;
  name: string;
  shape: AvatarShape;
  size: AvatarSize;
  onClick?: () => void;
  ariaLabel?: string;
  crossOrigin?: 'anonymous';
}

const getAvatarDimensions = (size: AvatarSize, shape: AvatarShape, theme: DefaultTheme) => {
  const { borderRadiusMd, borderRadiusCircle } = theme.constants;

  return shape === 'circle'
    ? getAvatarCircleStyles(size, borderRadiusCircle)
    : getAvatarSquareStyles(size, borderRadiusMd);
};

const sharedAvatarStyles = css<{ size: AvatarSize; shape: AvatarShape; onClick?: () => void }>`
  ${({ size, shape, theme }) => getAvatarDimensions(size, shape, theme)};
  cursor: ${({ onClick }) => onClick && 'pointer'};
`;

const AvatarImage = styled.img<{ size: AvatarSize; shape: AvatarShape }>`
  ${sharedAvatarStyles};
`;

export const StyledAvatar = styled.div<{ size: AvatarSize; shape: AvatarShape }>`
  flex-shrink: 0;
  align-items: center;
  color: ${({ theme: { vars } }) => vars.textSurface};
  display: flex;
  font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
  height: 100%;
  justify-content: center;
  ${sharedAvatarStyles};
`;

const AvatarWithInitials = styled(StyledAvatar)`
  ${({ size }) => getFontStyle(size)};
  background-color: ${({ theme: { vars } }) => vars.textDisabled};
`;

const AvatarWithIcon = styled(StyledAvatar)`
  /* TODO: add correct color  */
  background-color: ${({ theme: { vars } }) => vars.shadowBackground1};
  color: ${({ theme: { vars } }) => vars.textDefault};
`;

const AvatarWithEmoji = styled(StyledAvatar)`
  background-color: ${({ theme: { vars } }) => vars.shadowBackground1};
  ${({ size }) => getFontStyle(size)};
`;

const getCompletionCircleSize = (size: AvatarSize): number => {
  switch (size) {
    case '2xs':
      return 0;
    case 'xs':
      return 0;
    case 'sm':
      return 44;
    case 'md':
      return 52;
    case 'lg':
      return 64;
    case 'xl':
      return 86;
    case '2xl':
      return 160;
  }
};

const getCompletionCircleBarWidth = (size: AvatarSize): number => {
  switch (size) {
    case '2xs':
      return 0;
    case 'xs':
      return 0;
    case 'sm':
      return 5;
    case 'md':
      return 5;
    case 'lg':
      return 7;
    case 'xl':
      return 10;
    case '2xl':
      return 14;
  }
};

const getMatchingIconSize = (size: AvatarSize): IconSize => {
  switch (size) {
    case '2xs':
      return '2xs';
    case 'xs':
    case 'sm':
    case 'md':
    case 'lg':
      return 'xs';
    case 'xl':
    case '2xl':
      return 'md';
  }
};

interface CompletionStatusProps {
  circleSize: number;
  completionPercent: number;
  barWidth: number;
}

export const CompletionStatusCircle = ({
  circleSize,
  completionPercent,
  barWidth,
}: CompletionStatusProps) => {
  const theme = useTheme();
  const fillColor = useProgressColor({ percent: completionPercent });
  const data = [{ value: completionPercent, fill: fillColor }];

  return (
    <RadialBarChart
      data={data}
      endAngle={-270}
      height={circleSize}
      id='user-avatar-progress-bar'
      innerRadius={(circleSize - barWidth) / 2}
      outerRadius={(circleSize + barWidth) / 2}
      startAngle={90}
      width={circleSize}
    >
      <PolarAngleAxis angleAxisId={0} domain={[0, 100]} tick={false} type='number' />
      <RadialBar
        background={{ fill: theme.vars.borderSurface1 }}
        cornerRadius={5}
        dataKey='value'
      />
    </RadialBarChart>
  );
};
const CompletionStatusWrapper = styled(FlexContainer)<{ circleSize: number }>`
  justify-content: flex-start;
  height: ${({ circleSize }) => `${circleSize}px`};
`;

const CompletionStatusAlignmentWrapper = styled(FlexContainer)`
  position: relative;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const AvatarWrapper = styled(FlexContainer)`
  position: absolute;
`;

const StyledSpan = styled.span`
  pointer-events: none;
`;

const Avatar = (props: Props) => {
  const {
    className,
    completionPercent,
    shape,
    size,
    image,
    emoji,
    icon,
    name,
    onClick,
    ariaLabel,
    crossOrigin,
  } = props;
  const initialsText = userInitials(name);
  const circleSize = getCompletionCircleSize(size);
  const barWidth = getCompletionCircleBarWidth(size);
  const paywallCtx = useContext(PaywallContext);
  const displayCompletions = !paywallCtx.includes('completions');
  const renderCompletionCircle =
    displayCompletions && completionPercent != undefined && shape == 'circle' && circleSize != 0;

  if (image && renderCompletionCircle) {
    return (
      <CompletionStatusWrapper circleSize={circleSize} className='avatar-completion-ring'>
        <CompletionStatusAlignmentWrapper>
          <CompletionStatusCircle
            barWidth={barWidth}
            circleSize={circleSize}
            completionPercent={completionPercent}
          />
          <AvatarWrapper>
            <AvatarImage
              alt={initialsText}
              aria-label={ariaLabel}
              className={className}
              onClick={onClick}
              shape={shape}
              size={size}
              src={image}
            />
          </AvatarWrapper>
        </CompletionStatusAlignmentWrapper>
      </CompletionStatusWrapper>
    );
  } else if (renderCompletionCircle) {
    return (
      <CompletionStatusWrapper circleSize={circleSize} className='avatar-completion-ring'>
        <CompletionStatusAlignmentWrapper>
          <CompletionStatusCircle
            barWidth={barWidth}
            circleSize={circleSize}
            completionPercent={completionPercent}
          />
          <AvatarWrapper>
            <AvatarWithInitials
              aria-label={ariaLabel}
              className={className}
              onClick={onClick}
              shape={shape}
              size={size}
            >
              <StyledSpan className='notranslate'>{initialsText}</StyledSpan>
            </AvatarWithInitials>
          </AvatarWrapper>
        </CompletionStatusAlignmentWrapper>
      </CompletionStatusWrapper>
    );
  } else if (image) {
    return (
      <AvatarImage
        alt={initialsText}
        aria-label={ariaLabel}
        className={className}
        onClick={onClick}
        shape={shape}
        size={size}
        src={image}
        {...(crossOrigin ? { crossOrigin } : {})} // Need to set cross-origin to 'anonymous' to resolve CORS error when downloading an org char
      />
    );
  } else if (emoji) {
    return (
      <AvatarWithEmoji onClick={onClick} shape={shape} size={size}>
        {emoji}
      </AvatarWithEmoji>
    );
  } else if (icon) {
    return (
      <AvatarWithIcon className='no-users-empty-avatar' onClick={onClick} shape={shape} size={size}>
        <Icon name={icon} size={getMatchingIconSize(size)}></Icon>
      </AvatarWithIcon>
    );
  }
  return (
    <AvatarWithInitials
      aria-label={ariaLabel}
      className={className}
      onClick={onClick}
      shape={shape}
      size={size}
    >
      <StyledSpan className='notranslate'>{initialsText}</StyledSpan>
    </AvatarWithInitials>
  );
};

export default Avatar;
